/*Color variables*/
/*gradient variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*alerts*/
/*navigation*/
/*dark colors*/
/*---FONTS IMPORT --- */
body.dark-theme {
  color: #eaecf3;
  background: var(--black120);
}

@media (max-width: 480px) {
  .dark-theme .border-end.bd-xs-e-0 {
    border-right: 0 !important;
  }

  .dark-theme .border-end.bd-xs-s-0 {
    border-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .dark-theme .border-end.bd-md-e-0 {
    border-right: 0 !important;
  }
}

@media (max-width: 1024px) {
  .dark-theme .border-end.bd-lg-e-0 {
    border-right: 0 !important;
  }
}

@media (max-width: 1366px) {
  .dark-theme .border-end.bd-xl-e-0 {
    border-right: 0 !important;
  }
}

@media (max-width: 480px) {
  .dark-theme.rtl .border-end.bd-xs-e-0 {
    border-left: 0 !important;
    border-right: inherit !important;
  }

  .dark-theme.rtl .border-end.bd-xs-s-0 {
    border-right: 0 !important;
    border-left: inherit !important;
  }
}

@media (max-width: 991px) {
  .dark-theme.rtl .border-end.bd-md-e-0 {
    border-left: 0 !important;
    border-right: inherit !important;
  }
}

@media (max-width: 1024px) {
  .dark-theme.rtl .border-end.bd-lg-e-0 {
    border-left: 0 !important;
    border-right: inherit !important;
  }
}

@media (max-width: 1366px) {
  .dark-theme.rtl .border-end.bd-xl-e-0 {
    border-left: 0 !important;
    border-right: inherit !important;
  }
}

.dark-theme .tree li i {
  color: var(--primary-bg-color);
}

.dark-theme .bootstrap-tagsinput .badge {
  background-color: var(--primary-bg-color);
  border: 1px solid var(--primary-bg-border);
}

.dark-theme .sweet-alert button {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .ql-snow.ql-toolbar button:hover,
.dark-theme .ql-snow .ql-toolbar button:hover,
.dark-theme .ql-snow.ql-toolbar button:focus,
.dark-theme .ql-snow .ql-toolbar button:focus,
.dark-theme .ql-snow.ql-toolbar button.ql-active,
.dark-theme .ql-snow .ql-toolbar button.ql-active,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label:hover,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label:hover,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item:hover,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item:hover,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-bg-color);
}

.dark-theme .ql-snow.ql-toolbar button:hover .ql-fill,
.dark-theme .ql-snow .ql-toolbar button:hover .ql-fill,
.dark-theme .ql-snow.ql-toolbar button:focus .ql-fill,
.dark-theme .ql-snow .ql-toolbar button:focus .ql-fill,
.dark-theme .ql-snow.ql-toolbar button.ql-active .ql-fill,
.dark-theme .ql-snow .ql-toolbar button.ql-active .ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.dark-theme .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.dark-theme .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.dark-theme .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.dark-theme .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.dark-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.dark-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.dark-theme
  .ql-snow
  .ql-toolbar
  .ql-picker-item.ql-selected
  .ql-stroke.ql-fill {
  fill: var(--primary-bg-color);
}

.dark-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.dark-theme .ql-snow .ql-toolbar button:hover .ql-stroke,
.dark-theme .ql-snow.ql-toolbar button:focus .ql-stroke,
.dark-theme .ql-snow .ql-toolbar button:focus .ql-stroke,
.dark-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.dark-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.dark-theme .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.dark-theme .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.dark-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.dark-theme .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.dark-theme .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary-bg-color);
}

.dark-theme .ql-snow a {
  color: var(--primary-bg-color);
}

.dark-theme .lg-actions .lg-next,
.dark-theme .lg-actions .lg-prev {
  background-color: var(--primary05);
  border: 1px solid var(--primary-bg-color);
}

.dark-theme .lg-toolbar {
  background-color: var(--primary05);
}

.dark-theme .datepicker .datepicker-switch {
  color: var(--primary-bg-color);
}

.dark-theme .datepicker .datepicker-switch:hover,
.dark-theme .datepicker .prev:hover,
.dark-theme .datepicker .next:hover,
.dark-theme .datepicker tfoot tr th:hover {
  color: var(--primary-bg-color) !important;
}

.dark-theme .datepicker table tr td.disabled,
.dark-theme .datepicker table tr td.disabled:hover {
  color: var(--primary-bg-color);
}

.dark-theme .datepicker table tr td.active,
.dark-theme .datepicker table tr td.active:hover,
.dark-theme .datepicker table tr td.active.disabled,
.dark-theme .datepicker table tr td.active.disabled:hover {
  background-image: -moz-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: -ms-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(var(--primary-bg-color)),
    to(var(--primary-bg-color))
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: -o-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-repeat: repeat-x;
  filter: gradient(
    startColorstr= "$primary",
    endColorstr= "$primary",
    GradientType=0
  );
  border-color: var(--primary-bg-color);
}

.dark-theme .datepicker table tr td span:hover,
.dark-theme .datepicker table tr td span.focused {
  background: var(--primary-bg-color);
}

.dark-theme .datepicker table tr td span.disabled,
.dark-theme .datepicker table tr td span.disabled:hover {
  background: none;
  color: var(--primary-bg-color);
}

.dark-theme .datepicker table tr td span.active,
.dark-theme .datepicker table tr td span.active:hover,
.dark-theme .datepicker table tr td span.active.disabled,
.dark-theme .datepicker table tr td span.active.disabled:hover {
  background-image: -moz-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: -ms-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(var(--primary-bg-color)),
    to(var(--primary-bg-color))
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: -o-linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  background-image: linear-gradient(
    to bottom,
    var(--primary-bg-color),
    var(--primary-bg-color)
  );
  filter: gradient(
    startColorstr= "$primary",
    endColorstr= "$primary",
    GradientType=0
  );
  border-color: var(--primary-bg-color);
}

.dark-theme .datepicker table tr td span.old,
.dark-theme .datepicker table tr td span.new {
  color: var(--primary-bg-color);
}

.dark-theme .form-control:focus {
  border-color: var(--primary-bg-color);
}

.dark-theme .btn-white:not(:disabled):not(.disabled):active,
.dark-theme .btn-white:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #2a2e3f;
  border-color: #484863 !important;
}

.dark-theme .btn-white:focus {
  background-color: #2a2e3f !important;
  color: #fff;
  border-color: #484863 !important;
}

.dark-theme .qty {
  border-left: 0 !important;
  border-right: 0 !important;
}

.dark-theme .card {
  background-color: #2a2e3f !important;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.dark-theme .main-header {
  background: #2a2e3f;
  border-bottom: 1px solid #ededf5;
  box-shadow: none;
}

.dark-theme .side-header {
  border-right: 0;
}

.dark-theme .header-icons .new.nav-link {
  color: #fff;
}

.dark-theme .card-dashboard-audience-metrics .card-header,
.dark-theme .card-dashboard-audience-metrics .card-body {
  background-color: #2a2e3f;
}

.dark-theme .card-header {
  background-color: #2a2e3f;
  border-bottom: 0;
}

.dark-theme .border-bottom {
  border-bottom: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .border-top {
  border-top: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .border-right {
  border-right: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .border-left {
  border-left: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .border {
  border: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .table tbody tr,
.dark-theme .table-bordered thead th {
  background-color: #2a2e3f;
}

.table-bordered thead td {
  background-color: #2a2e3f;
}

.dark-theme .table {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid #3c3f50;
}

.dark-theme .table-bordered {
  border: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .table-bordered th,
.dark-theme .table-bordered td {
  border: 1px solid rgba(234, 236, 241, 0.1);
}

.dark-theme .card-dashboard-audience-metrics .card-body h4 {
  color: #fcfcfd;
}

.dark-theme .progress {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme
  .card-dashboard-audience-metrics
  .flot-chart
  .flot-x-axis
  > div
  span:last-child {
  color: #b9c6de;
}

.dark-theme .main-footer {
  background-color: #2a2e3f;
  border-top: 1px solid #484863;
}

.dark-theme .sidebar.sidebar-right {
  box-shadow: 5px 7px 26px -5px #171a21 !important;
}

.dark-theme .sidebar .tabs-menu ul {
  border-bottom: 1px solid rgba(235, 234, 241, 0.1);
}

.dark-theme .chat .contacts li {
  border-bottom: 1px solid rgba(227, 227, 247, 0.1);
}

.dark-theme .form-control {
  color: #fff;
  background-color: #2a2e3f;
  border: 1px solid #484863;
}

.dark-theme .list-group-item {
  background-color: #2a2e3f !important;
  border: 1px solid #484863;
  color: #bdbac0;
}

.dark-theme .main-header-center .form-control {
  border-color: rgba(255, 255, 255, 0.1) !important;
  background-color: #2a2e3f;
}

.dark-theme .main-header form[role="search"].active input {
  background: #2a2e3f;
}

.dark-theme .main-header form[role="search"] button[type="reset"] {
  background: transparent;
}

.dark-theme .main-header .input-group-btn .btn:hover i {
  color: #fff;
}

.dark-theme .main-header-notification .dropdown-menu {
  background-color: #272738;
}

.dark-theme .notification-label {
  color: #fff;
}

.dark-theme .main-notification-list a:hover,
.dark-theme .main-message-list a:hover {
  background: rgba(255, 255, 255, 0.05);
}

.dark-theme .notification-subtext {
  color: rgba(255, 255, 255, 0.3);
}

.dark-theme .main-header-message .dropdown-footer,
.dark-theme .main-header-notification .dropdown-footer {
  border-top: 1px solid rgba(220, 225, 239, 0.1);
  background: #2a2e3f;
  border-radius: 0px 0px 3px 3px;
}

.dark-theme .dropdown-menu {
  color: #fff;
  background-color: #272738;
  border: 1px solid #4a4f62;
}

.dark-theme .dropdown-item {
  background-color: #383d52;
}

.dark-theme .dropdown-menu::after {
  background-color: #383d52;
  border: 1px solid #4a4f62;
}

.dark-theme .main-message-list a .name {
  color: #fff;
}

.dark-theme .main-message-list a .time,
.dark-theme .main-message-list a .desc {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .task-line a {
  color: #fff;
}

.dark-theme .latest-tasks .nav-tabs .nav-link {
  color: #bc289a;
  background: transparent;
}

.dark-theme .chips p {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme .chip {
  color: #fff;
  background-color: rgba(245, 246, 251, 0.1);
}

.dark-theme .form-select {
  color: #ffffe3;
  background-color: #2a2e3f;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  border: 1px solid #444566;
}

.dark-theme .ps__thumb-y {
  background-color: #484863;
}

.dark-theme .ps__rail-y:hover > .ps__thumb-y,
.dark-theme .ps__rail-y:focus > .ps__thumb-y,
.dark-theme .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #484863;
  width: 8px;
}

@media (min-width: 1245px) {
  .dark-theme .main-header-center .form-control {
    border-color: rgba(255, 255, 255, 0.1) !important;
    background-color: transparent;
  }
}

.chip:hover {
  background: #8485fb;
  color: #fff;
}

.dark-theme .latest-tasks .check-box .ckbox span:before {
  border: 1px solid #0f0f20;
}

.dark-theme .table th,
.dark-theme .table td {
  border-top: 1px solid #484863;
}

.dark-theme #global-loader {
  background: #1a233a;
}

.dark-theme .app-sidebar {
  background: #2a2e3f;
  border-top: 0;
  box-shadow: 0px 8px 14.72px rgb(154 154 204 / 10%);
  border-right: 1px solid #ededf5;
}

.dark-theme .main-sidebar-header {
  background: #2a2e3f;
  background: #2a2e3f;
}

.dark-theme .side-menu__label,
.dark-theme .customeLink,
.dark-theme .side-menu .side-menu__icon .dark-text {
  color: #a9abbd;
  fill: #a9abbd;
}

.dark-theme .app-sidebar__user .user-info h4 {
  color: #fff !important;
}

.dark-theme .side-menu h3 {
  color: rgba(255, 255, 255, 0.4) !important;
}

.dark-theme .main-sidebar-header {
  border-bottom: 1px solid #ededf5;
  border-right: 1px solid #484863;
}

.dark-theme .main-sidebar-loggedin .media-body h6 {
  color: #fff;
}

.dark-theme .app-sidebar .slide.active .side-menu__item {
  background: transparent;
}

.dark-theme .slide a {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .slide.is-expanded {
  background: transparent;
}

.dark-theme .slide.is-expanded a {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .side-menu__item {
  color: rgba(255, 255, 255, 0.7);
}

.dark-theme .app-sidebar__user .user-pro-body img {
  border: 2px solid var(--primary-bg-color);
  background: rgba(109, 110, 243, 0.2);
}

.dark-theme .slide.is-expanded:before {
  background: #484863;
}

.dark-theme .slide-menu .slide-item:before {
  border-color: #6d7582;
}

.dark-theme .main-logo.dark-theme {
  display: block;
}

.dark-theme .desktop-logo {
  margin: 0 auto;
  display: none;
}

.dark-theme .open-toggle svg g,
.dark-theme .close-toggle svg g {
  fill: #fff;
}

.dark-theme .angle {
  color: #a9abbd !important;
}

.dark-theme .main-header-center .btn:hover,
.dark-theme .main-header-center .sp-container button:hover {
  color: #fff;
}

.dark-theme .sp-container .main-header-center button:hover {
  color: #fff;
}

.dark-theme .main-header-center .btn:focus,
.dark-theme .main-header-center .sp-container button:focus {
  color: #fff;
}

.dark-theme .sp-container .main-header-center button:focus,
.dark-theme .main-header .input-group-btn .btn i {
  color: #fff;
}

.dark-theme .main-mail-item {
  border-top: 1px solid #484863;
  border-bottom: 1px solid #484863;
}

.dark-theme .main-mail-item.unread {
  background-color: #2a2e3f;
}

.dark-theme .main-content-title {
  color: #fff;
}

.dark-theme .main-mail-options {
  border: 1px solid #484863;
  border-bottom: 0;
}

.dark-theme .main-mail-list {
  border: 1px solid #484863;
}

.dark-theme .main-mail-item {
  background-color: #2a2e3f;
}

.dark-theme .main-mail-subject strong {
  color: rgba(255, 255, 255, 0.7);
}

.dark-theme .ckbox span:before {
  background-color: #1d1d2a;
  border: 1px solid #484863;
}

.dark-theme .main-mail-star {
  color: rgba(255, 255, 255, 0.2);
}

.dark-theme .main-nav-column .nav-link {
  color: #c3c7d1;
}

.dark-theme .main-nav-column .nav-link:hover,
.dark-theme .main-nav-column .nav-link:focus {
  color: #fff;
}

.dark-theme .btn-light {
  color: #fff;
  background-color: #484863 !important;
  border-color: rgba(189, 198, 214, 0.2);
}

.dark-theme .main-nav-column .nav-link:hover i:not([class*=" tx-"]),
.dark-theme .main-nav-column .nav-link:focus i:not([class*=" tx-"]) {
  color: #fff;
}

.dark-theme .nav-search .input-group-text {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 0;
}

.dark-theme .main-nav-column .nav-link.active {
  color: var(--primary-bg-color) !important;
}

.dark-theme .main-nav-column .nav-link.active:hover,
.dark-theme .main-nav-column .nav-link.active:focus {
  color: var(--primary-bg-color) !important;
}

.dark-theme .main-mail-header .btn-group .btn {
  border-color: #555c6e;
  background-color: #555c6e;
}

.dark-theme .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #3c3f50;
}

.dark-theme .table.dataTable > :not(:last-child) > :last-child > * {
  border-bottom-color: transparent;
}

/*----- Left-Sidemenu -----*/
.main-mail-header .btn-group .sp-container button,
.sp-container .main-mail-header .btn-group button {
  border-color: #555c6e;
  background-color: #555c6e;
}

.dark-theme .main-mail-header .btn-group .btn.disabled,
.dark-theme .main-mail-header .btn-group .sp-container button.disabled {
  background-color: #555c6e;
  color: #ededf5;
  border-color: #555c6e;
}

.dark-theme .sp-container .main-mail-header .btn-group button.disabled {
  background-color: #555c6e;
  color: #ededf5;
  border-color: #555c6e;
}

.dark-theme .main-mail-header .btn-group .btn:hover,
.dark-theme .main-mail-header .btn-group .sp-container button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}

.dark-theme .sp-container .main-mail-header .btn-group button:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}

.dark-theme .main-mail-header .btn-group .btn:focus,
.dark-theme .main-mail-header .btn-group .sp-container button:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}

.dark-theme .sp-container .main-mail-header .btn-group button:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  border-left: 0;
}

.dark-theme .card-header,
.dark-theme .card-footer {
  position: relative;
  border-color: #484863;
}

.dark-theme hr {
  border-color: rgb(89, 90, 118);
}

.dark-theme .main-content-label,
.dark-theme .card-table-two .card-title,
.dark-theme .card-dashboard-eight .card-title {
  color: #fff;
}

.dark-theme .form-label {
  color: #cfdaec;
}

.dark-theme .select2-container--default .select2-selection--single {
  background-color: #2a2e3f;
  border-color: #484863 !important;
}

.dark-theme
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #cfdaec;
}

.dark-theme .select2-dropdown {
  background-color: #2a2e3f;
  border-color: rgba(255, 255, 255, 0.05);
}

.dark-theme
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-theme
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  border-color: rgba(226, 232, 245, 0.2);
  background: #2a2e3f;
  color: #fff;
}

.dark-theme .main-nav-line-chat {
  border-bottom: 1px solid #484863 !important;
}

.dark-theme .main-nav-line .nav-link {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-chat-msg-name h6 {
  color: #fff;
}

.dark-theme .main-chat-header {
  border-bottom: 1px solid #484863;
  box-shadow: 2px 3px 14px #1a233a;
}

.dark-theme .main-nav-line-chat .nav-link.active {
  color: #ffffff !important;
  background: var(--primary-bg-color);
}

.dark-theme .main-chat-contacts-more {
  background-color: var(--primary-bg-color);
}

.dark-theme .main-chat-list .media {
  border-bottom: 1px solid #484863;
}

.dark-theme .main-chat-list .media.new {
  background-color: #2a2e3f;
}

.dark-theme .main-chat-list .media.new .media-body p {
  color: #a9b2c7;
}

.dark-theme .main-chat-list .media.new .media-contact-name span:first-child {
  color: #f3f6fb;
}

.dark-theme .main-chat-list .media-contact-name span:first-child {
  color: #fff !important;
}

.dark-theme .main-chat-list .media.selected {
  background-color: rgba(243, 246, 251, 0.1);
}

.dark-theme .main-chat-contacts-wrapper {
  border-bottom: 1px solid #484863;
}

.dark-theme .main-chat-list .media:hover,
.dark-theme .main-chat-list .media:focus {
  background-color: rgba(252, 252, 252, 0.05);
}

.dark-theme .main-chat-list .media.selected .media-body p {
  color: #b7bfd2;
}

.dark-theme .main-msg-wrapper {
  background-color: #484863;
  color: #fff;
}

.dark-theme .main-chat-footer {
  background-color: #2a2e3f;
  z-index: 999;
}

.dark-theme .main-chat-footer .form-control {
  background: transparent;
  border-color: transparent;
}

.dark-theme .irs-line-mid,
.dark-theme .irs-line-left,
.dark-theme .irs-line-right {
  background-color: #484863;
}

.dark-theme .irs-min,
.dark-theme .irs-max {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme .main-calendar .fc-header-toolbar h2 {
  color: #fff;
}

.dark-theme .ui-datepicker {
  background-color: #2a2e3f;
  box-shadow: 0 0 24px rgba(20, 28, 43, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2a2e3f;
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td span,
.dark-theme .ui-datepicker .ui-datepicker-calendar td a {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .ui-datepicker .ui-datepicker-title,
.dark-theme .ui-datepicker .ui-datepicker-calendar th {
  color: #fff;
}

.dark-theme .main-datepicker .ui-datepicker .ui-datepicker-calendar th {
  color: var(--primary02);
}

.dark-theme .card--calendar .ui-datepicker .ui-datepicker-header {
  border-bottom: 1px solid rgba(227, 227, 227, 0.1);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td a:hover {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.dark-theme .main-calendar .fc-view > table {
  background-color: #2a2e3f;
}

.dark-theme .main-calendar .fc-head-container .fc-day-header {
  color: #fff;
}

.dark-theme .main-calendar .fc-view .fc-day-number {
  color: #fff;
}

.dark-theme .main-calendar .fc-view .fc-other-month {
  background-color: rgba(40, 92, 247, 0.07);
}

.dark-theme .main-calendar .fc-content {
  border-color: #484863;
}

.main-calendar .fc-divider {
  border-color: #484863;
}

.dark-theme .main-calendar .fc-list-heading td,
.dark-theme .main-calendar .fc-list-view,
.dark-theme .main-calendar .fc-popover,
.dark-theme .main-calendar .fc-row,
.dark-theme .main-calendar tbody,
.dark-theme .main-calendar td {
  border-color: #484863;
}

.main-calendar th {
  border-color: #484863;
}

.dark-theme .main-calendar thead {
  border-color: #484863;
}

.dark-theme .main-calendar .fc-view .fc-day-number:hover,
.dark-theme .main-calendar .fc-view .fc-day-number:focus {
  color: #fff;
  background-color: transparent;
}

.dark-theme .main-calendar td.fc-today {
  background-color: var(--primary-bg-color);
}

.dark-theme .main-calendar .fc-view > table > thead th,
.dark-theme .main-calendar .fc-view > table > thead td {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .main-calendar .fc-view > table > tbody > tr > td {
  border-color: #484863;
}

.dark-theme .main-calendar .fc-header-toolbar button {
  background-color: #384361;
  border: 1px solid #484863;
}

.dark-theme .main-calendar .fc-header-toolbar button.fc-state-active {
  background-color: var(--primary-bg-color);
}

.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
  background-color: #333d5a;
}

.dark-theme .fc-unthemed .fc-divider,
.dark-theme .fc-unthemed .fc-list-heading td,
.dark-theme .fc-unthemed .fc-popover .fc-header {
  background: #384361;
}

.dark-theme
  .main-calendar
  .fc-view.fc-listMonth-view
  .fc-list-item-title
  .fc-desc,
.dark-theme
  .main-calendar
  .fc-view.fc-listWeek-view
  .fc-list-item-title
  .fc-desc {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a,
.dark-theme
  .main-calendar
  .fc-view.fc-listMonth-view
  .fc-list-heading-main
  span:last-child,
.dark-theme
  .main-calendar
  .fc-view.fc-listWeek-view
  .fc-list-heading-main
  span:last-child {
  color: #fff;
}

.dark-theme .main-contact-info-header .media-body p,
.dark-theme .tx-inverse {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .contact-icon:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.dark-theme .main-contact-info-header {
  border-bottom: 1px solid #484863;
}

.dark-theme .main-contact-info-body .media-body span {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .main-contact-info-body .media-body label {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-contact-info-body .media + .media::before {
  border-top: 1px dotted rgba(226, 232, 245, 0.2);
}

.dark-theme .main-contact-body span {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-contact-body h6 {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .main-contact-item.selected {
  border-left-color: var(--primary-bg-color);
  border-top-color: #484863 !important;
  border-bottom-color: #484863 !important;
  background-color: #484863;
}

.dark-theme .main-contact-item + .main-contact-item {
  border-top-color: rgba(227, 231, 237, 0.19);
}

.dark-theme .main-contact-item + .main-contact-item::before {
  border-top: 1px solid rgba(227, 231, 237, 0.05);
}

.dark-theme .main-contact-item:hover,
.dark-theme .main-contact-item:focus {
  background-color: #484863 !important;
  border-top-color: #484863;
  border-bottom-color: #484863;
}

.dark-theme .main-contact-label::after {
  border-bottom: 1px solid #484863;
}

.dark-theme .notifit_confirm,
.dark-theme .notifit_prompt {
  background-color: #2a2e3f;
}

.dark-theme .tree li {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .tree li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .tree ul li:last-child:before {
  background: #141432;
}

.dark-theme .tree ul:before {
  border-left-color: rgba(227, 227, 227, 0.2);
  border-right-color: rgba(227, 227, 227, 0.2);
}

.dark-theme .tree ul li:before {
  border-top-color: rgba(227, 227, 227, 0.2);
}

.dark-theme .text-muted {
  color: #aeb7c6 !important;
}

.dark-theme .main-icon-group {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .table thead th {
  border-bottom: 1px solid #484863;
  border-top: 0 !important;
  color: #fff;
}

.dark-theme .table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.04) !important;
}

.dark-theme table.dataTable {
  border: 1px solid #484863;
}

.dark-theme table.dataTable thead th,
.dark-theme table.dataTable thead td {
  color: #fff;
}

.dark-theme table.dataTable thead .sorting_asc,
.dark-theme table.dataTable thead .sorting_desc {
  background-color: #2a2e3f;
}

.dark-theme #example-delete.table thead th {
  border-bottom: 0;
}

.dark-theme .dataTables_wrapper .dataTables_length,
.dark-theme .dataTables_wrapper .dataTables_filter,
.dark-theme .dataTables_wrapper .dataTables_info,
.dark-theme .dataTables_wrapper .dataTables_processing,
.dark-theme .dataTables_wrapper .dataTables_paginate {
  color: #fff;
}

.dark-theme .dataTables_wrapper .dataTables_filter input {
  border: 1px solid rgba(226, 232, 245, 0.2);
}

.dark-theme .dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: transparent;
}

.dark-theme .page-link {
  color: #fff;
  background-color: #2a2e3f;
}

.dark-theme
  .dataTables_wrapper
  .dataTables_paginate
  .page-item.disabled
  .page-link {
  background-color: #2a2e3f;
  color: #a9abbd;
}

.dark-theme select option {
  background: #2a2e3f;
}

.dark-theme table.dataTable tbody tr.selected {
  background: #2a2e3f;
}

.dark-theme .example {
  padding: 1rem;
  border: 1px solid rgba(225, 230, 241, 0.1);
}

.dark-theme #icon-dismissalerts .alert-default.alert-dismissible .close {
  color: #fff;
}

.dark-theme .main-table-reference > thead > tr > th,
.dark-theme .main-table-reference > thead > tr > td {
  border: 1px solid #484863;
}

.dark-theme .main-table-reference > tbody > tr > th,
.dark-theme .main-table-reference > tbody > tr > td {
  border: 1px solid #484863;
}

.dark-theme code.language-markup {
  background: transparent;
  border: transparent;
}

.dark-theme .token {
  color: #dc2a2a;
}

.dark-theme .token.selector,
.dark-theme .token.attr-name,
.dark-theme .token.string,
.dark-theme .token.char,
.dark-theme .token.builtin,
.dark-theme .token.inserted {
  color: #4e9cf1;
}

.dark-theme .token.atrule,
.dark-theme .token.attr-value,
.dark-theme .token.keyword {
  color: #e40dae;
}

.dark-theme .token.operator,
.dark-theme .token.entity,
.dark-theme .token.url {
  color: #ecc494;
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme .language-css .token.string,
.dark-theme.style .token.string {
  color: #ecc494;
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme .clipboard-icon {
  background: #141b2d;
  border: 1px solid rgba(225, 230, 241, 0.1);
}

.dark-theme .main-table-reference {
  background: transparent;
}

.dark-theme .main-table-reference > thead > tr > th,
.dark-theme .main-table-reference > thead > tr > td {
  background: #2a2e3f !important;
  border: 1px solid rgba(225, 230, 241, 0.1) !important;
}

.dark-theme .breadcrumb-style .breadcrumb-item a,
.dark-theme .breadcrumb-style1 .breadcrumb-item a,
.dark-theme .breadcrumb-style2 .breadcrumb-item a,
.dark-theme .breadcrumb-style3 .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .dropdown-item {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .dropdown-item:hover,
.dark-theme .dropdown-item:focus {
  background: rgba(255, 255, 255, 0.05);
}

.dark-theme .dropdown-divider {
  border-top: 1px solid rgba(227, 231, 237, 0.16);
}

.dark-theme .img-thumbnail {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #484863;
}

.dark-theme .img-thumbnail p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}

.dark-theme .bd {
  border-color: #484863;
}

.dark-theme .main-nav .nav-link:hover,
.dark-theme .main-nav .nav-link:focus {
  color: #fff;
}

.dark-theme .nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .nav-pills .nav-link:hover,
.dark-theme .nav-pills .nav-link:focus {
  color: #fff;
}

.dark-theme .nav-pills .nav-link.active {
  color: #fff !important;
}

.dark-theme .main-nav .nav-link {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .bg-gray-300 {
  background-color: rgba(255, 255, 255, 0.3);
}

.dark-theme #tab .bg-gray-300 {
  background-color: #37415f;
  border-bottom: 1px solid #404563;
}

.dark-theme .nav-tabs .nav-link {
  color: rgb(255, 255, 255);
}

.dark-theme .nav-tabs .nav-link.active,
.dark-theme .nav-tabs .nav-link:hover,
.dark-theme .nav-tabs .nav-link:focus {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.dark-theme .popover-static-demo {
  background-color: #2a2e3f;
}

.dark-theme .popover {
  background-color: #2a2e3f;
  border-color: #3d4152;
}

.dark-theme .popover-body {
  color: rgba(255, 255, 255, 0.7);
}

.dark-theme .popover-header {
  color: rgba(255, 255, 255, 0.7);
  background-color: #2a2e3f;
  border-color: #373e52;
}

.dark-theme .bs-popover-top > .arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^="top"] > .arrow::before {
  border-top-color: rgb(78, 86, 109);
}

.dark-theme .bs-popover-top > .arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^="top"] > .arrow::after {
  border-top-color: #373e52;
}

.dark-theme .bs-popover-bottom > .arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^="bottom"] > .arrow::after {
  border-bottom-color: #373e52;
}

.dark-theme .bs-popover-bottom > .arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^="bottom"] > .arrow::before {
  border-bottom-color: rgb(78, 86, 109);
}

.dark-theme .bs-popover-left > .arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^="left"] > .arrow::after,
.dark-theme .bs-popover-left > .arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^="left"] > .arrow::after {
  border-left-color: #373e52;
}

.dark-theme .bs-popover-right > .arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^="right"] > .arrow::after {
  border-right-color: #373e52;
}

.dark-theme .bs-popover-right > .arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^="right"] > .arrow::before {
  border-right-color: rgb(78, 86, 109);
}

.dark-theme .bs-popover-left > .arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^="left"] > .arrow::before {
  border-left-color: rgb(78, 86, 109);
}

.dark-theme .bg-gray-200 {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .media-body {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .bg-gray-100 {
  background-color: rgba(255, 255, 255, 0.01);
}

.dark-theme .tooltip-static-demo {
  background-color: #2a2e3f;
}

.dark-theme .toast-header {
  border-bottom-color: rgba(205, 212, 224, 0.2);
}

.dark-theme .toast {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(84, 90, 109, 0.7);
}

.dark-theme .toast-header {
  color: rgba(255, 255, 255, 0.58);
  background: var(--primary02);
}

.dark-theme .bootstrap-tagsinput {
  background-color: transparent;
}

.dark-theme .tag {
  color: #fff;
  background-color: rgba(239, 239, 245, 0.1);
}

.dark-theme .accordion .card-header a {
  color: #fff;
  background-color: #2a2e3f;
}

.dark-theme .accordion .card-body {
  background-color: #2a2e3f;
}

.dark-theme .accordion .card-header a.collapsed:hover,
.dark-theme .accordion .card-header a.collapsed:focus {
  color: #fff;
  background-color: #2a2e3f;
}

.dark-theme .modal-content {
  background-color: #2a2e3f;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.dark-theme .modal-header {
  border-bottom: 1px solid #484863;
}

.dark-theme .modal-title {
  color: #fff;
}
.dark-theme .modal-backdrop {
  background-color: #898989;
}

.dark-theme .modal-footer {
  border-top: 1px solid #484863;
}

.dark-theme .modal-content-demo .modal-body h6 {
  color: #fff;
}

.dark-theme .vtimeline .timeline-wrapper .timeline-panel {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 16px 0 rgba(47, 53, 84, 0.24);
}

.dark-theme
  .vtimeline
  .timeline-wrapper.timeline-inverted
  .timeline-panel:after {
  border-right: 14px solid #353949;
  border-left: 0 solid #2a2e3f;
}

.dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
  border-left: 14px solid #353949;
  border-right: 0 solid #2a2e3f;
}

.dark-theme .vtimeline:before {
  background-color: #1d1d2a;
}

.dark-theme .timeline-body {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .sweet-alert {
  background-color: #2a2e3f;
}

.dark-theme .sweet-alert h2 {
  color: #fff;
}

.dark-theme .btn-outline-light {
  border-color: #595e6d;
  color: #97a3b9;
}

.dark-theme .btn-outline-light:hover,
.dark-theme .btn-outline-light:focus {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #595e6d !important;
  box-shadow: none !important;
  color: #fff !important;
}

.dark-theme .main-content-body-profile .nav {
  border-bottom: 1px solid #484863;
}

.dark-theme .card-body + .card-body {
  border-top: 1px solid #484863;
}

.dark-theme .rating-stars input {
  color: #fff;
  background-color: #2a2e3f;
  border: 1px solid rgba(234, 237, 241, 0.1);
}

.dark-theme .rating-stars .rating-stars-container .rating-star {
  color: #2e3954;
}

.dark-theme .rating-stars .rating-stars-container .rating-star.is--active,
.dark-theme .rating-stars .rating-stars-container .rating-star.is--hover {
  color: #f1c40f !important;
}

.dark-theme .br-theme-bars-horizontal .br-widget a.br-active,
.dark-theme .br-theme-bars-horizontal .br-widget a.br-selected {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .br-theme-bars-pill .br-widget a.br-active,
.dark-theme .br-theme-bars-pill .br-widget a.br-selected {
  background-color: var(--primary-bg-color) !important;
  color: white;
}

.dark-theme .br-theme-bars-1to10 .br-widget a,
.dark-theme .br-theme-bars-movie .br-widget a,
.dark-theme .br-theme-bars-horizontal .br-widget a {
  background-color: var(--primary02);
}

.dark-theme .br-theme-bars-1to10 .br-widget a.br-active,
.dark-theme .br-theme-bars-1to10 .br-widget a.br-selected {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .br-theme-bars-movie .br-widget a.br-active,
.dark-theme .br-theme-bars-movie .br-widget a.br-selected {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .br-theme-bars-square .br-widget a {
  border: 2px solid #4f576f;
  background-color: #2a2e3f;
  color: #8694a5;
}

.dark-theme .br-theme-bars-square .br-widget a.br-active,
.dark-theme .br-theme-bars-square .br-widget a.br-selected {
  border: 2px solid var(--primary-bg-color);
  color: var(--primary-bg-color);
}

.dark-theme .br-theme-bars-pill .br-widget a {
  background-color: var(--primary02);
}

.dark-theme .custom-file-label {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.07);
}

.dark-theme .custom-file-label::after {
  color: #fff;
  background-color: var(--primary-bg-color);
  border: 1px solid var(--primary-bg-color);
}

.dark-theme .input-group-text {
  color: rgb(230, 226, 226);
  background-color: var(--primary02);
  border: 1px solid #484863;
}

.dark-theme .sp-replacer {
  border-color: rgba(255, 255, 255, 0.12);
  background-color: #2d3653;
}

.dark-theme .sp-replacer:hover,
.dark-theme .sp-replacer:focus {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .sp-container {
  background-color: #2a2e3f;
  border-color: rgba(226, 232, 245, 0.2);
}

.dark-theme
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme .select2-container--default .select2-selection--multiple {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-theme
  .select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .SumoSelect > .CaptionCont {
  border: 1px solid rgba(225, 230, 241, 0.1);
  color: #99a6b7;
  background-color: #2a2e3f;
}

.dark-theme .SumoSelect > .optWrapper {
  background: #2a2e3f;
  border: 1px solid rgba(234, 234, 236, 0.15);
  box-shadow: 0 2px 17px 2px rgb(7, 4, 86);
}

.dark-theme .SumoSelect .select-all {
  border-bottom: 1px solid rgba(234, 234, 236, 0.15);
  background-color: #2a2e3f;
}

.dark-theme .SumoSelect > .optWrapper > .options li.opt {
  border-bottom: 1px solid rgba(234, 234, 236, 0.15);
}

.dark-theme .SumoSelect > .optWrapper > .MultiControls {
  border-top: 1px solid rgba(234, 234, 236, 0.15);
  background-color: #2a2e3f;
}

.dark-theme .SumoSelect.open > .optWrapper {
  box-shadow: 0 2px 17px 2px rgb(28, 33, 64);
}

.dark-theme .SumoSelect > .optWrapper > .options li.opt:hover {
  background-color: rgba(244, 245, 245, 0.1);
}

.dark-theme .SumoSelect > .optWrapper.multiple > .options li.opt span i {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .SumoSelect .select-all > span i {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .dropify-wrapper {
  background-color: #2a2e3f;
  border: 1px solid rgba(239, 242, 247, 0.07);
  color: #fff;
}

.dark-theme .dropify-wrapper .dropify-preview {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .dropify-wrapper:hover {
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(250, 251, 254, 0.05) 25%,
    transparent 25%,
    transparent 50%,
    rgba(250, 251, 254, 0.1) 50%,
    rgba(250, 251, 254, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    rgba(250, 251, 254, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(250, 251, 254, 0.1) 50%,
    rgba(250, 251, 254, 0.1) 75%,
    transparent 75%,
    transparent
  );
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}

.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone {
  border: 2px dashed #5e6882;
  background-color: #2a2e3f;
  background-image: url(../plugins/fancyuploder/fancy_upload-dark.png);
}

.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:hover,
.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:focus,
.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:active {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .main-form-group {
  border: 1px solid #484863;
}

.dark-theme .main-form-group .form-control {
  padding: 0 15px;
}

.dark-theme .parsley-style-1 .parsley-input.parsley-error .form-control,
.dark-theme .parsley-style-1 .parsley-checkbox.parsley-error,
.dark-theme
  .parsley-style-1
  .parsley-select.parsley-error
  .select2-container--default
  .select2-selection--single {
  background-color: #141b2d;
}

.dark-theme .parsley-style-1 .wizard > .actions .disabled a {
  background-color: #ffffff;
  border-radius: 3px;
}

.dark-theme .wizard {
  border: 1px solid #484863;
  background-color: #2a2e3f;
}

.dark-theme .wizard > .content {
  border-top: 1px solid #484863;
  border-bottom: 1px solid #484863;
}

.dark-theme .wizard > .content > .title {
  color: #fff;
}

.dark-theme #wizard3.wizard.vertical > .content {
  border-top: 0;
  border-bottom: 0;
}

.dark-theme .ql-scrolling-demo {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .ql-scrolling-demo .ql-container .ql-editor {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .ql-snow .ql-picker-label {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.8);
}

.dark-theme .ql-snow .ql-editor,
.dark-theme .ql-snow.ql-toolbar button {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme .ql-snow.ql-toolbar {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .ql-snow.ql-container {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .ql-snow .ql-picker-options {
  background-color: #2a2e3f;
}

.dark-theme .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #545b6d;
}

.dark-theme #modalQuill .modal-header {
  border-bottom: 0;
}

.dark-theme .main-profile-work-list .media-body h6 {
  color: #fff;
}

.dark-theme .main-profile-work-list .media-body p {
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-mail-star.active {
  color: #ffbd5a;
}

.dark-theme .sub-slide-menu .sub-side-menu__item.active {
  color: var(--primary-bg-color);
}

.dark-theme .main-profile-contact-list .media-body div {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .main-profile-contact-list .media-body span {
  color: #fff;
}

.dark-theme .plan-icon {
  border: 1px solid rgba(245, 246, 251, 0.1);
  background: rgba(245, 246, 251, 0.1);
}

.dark-theme .bg-success-transparent {
  background-color: rgba(36, 213, 184, 0.17) !important;
}

.dark-theme .bg-primary-transparent {
  background-color: var(--primary02) !important;
}

.dark-theme .bg-secondary-transparent {
  background-color: rgba(247, 79, 117, 0.2) !important;
}

.dark-theme .bg-warning-transparent {
  background-color: rgba(255, 189, 90, 0.1) !important;
}

.dark-theme .bg-pink-transparent {
  background-color: rgba(247, 84, 250, 0.1) !important;
}

.dark-theme .bg-teal-transparent {
  background-color: rgba(41, 221, 238, 0.12) !important;
}

.dark-theme .bg-purple-transparent {
  background-color: rgba(173, 79, 250, 0.1) !important;
}

.dark-theme .bg-danger-transparent {
  background-color: rgba(243, 67, 67, 0.1) !important;
}

.dark-theme .main-profile-name,
.dark-theme .main-profile-body .media-body h6 {
  color: #fbfcff;
}

.dark-theme .main-profile-social-list .media-body a {
  color: #fff;
  opacity: 0.5;
}

.dark-theme .profile-footer a {
  background: #141b2d;
  color: #fff;
}

.dark-theme .billed-from h6 {
  color: #f4f5f8;
}

.dark-theme .invoice-title {
  color: rgb(225, 225, 225);
}

.dark-theme .main-invoice-list .media-body h6 {
  color: #fff;
}

.dark-theme .main-invoice-list .selected {
  background-color: rgba(244, 245, 248, 0.1);
  border-top-color: 1px dotted #484863;
  border-bottom-color: #484863;
  border-left-color: var(--primary-bg-color);
}

.dark-theme .main-invoice-list .media {
  border: 1px dotted #484863;
}

.dark-theme .main-invoice-list .media + .media::before {
  border-top: 1px dotted transparent;
}

.dark-theme .main-invoice-list .media:hover,
.dark-theme .main-invoice-list .media:focus {
  background-color: rgba(244, 245, 248, 0.1);
}

.dark-theme .main-invoice-list .media-body p {
  color: #9da5b5;
}

.dark-theme .main-invoice-list .media-body p span {
  color: #cbcfd8;
}

.dark-theme .table-invoice tbody > tr > th:first-child .invoice-notes p {
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 991px) {
  .dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right: 14px solid #353949 !important;
    border-left: 0 solid #141b2d !important;
  }
}

@media (min-width: 576px) {
  .dark-theme .wizard.vertical > .content,
  .dark-theme .wizard.vertical > .actions {
    border-left-color: #484863;
    border-right-color: #484863;
  }
}

.table-invoice tbody > tr > td:first-child .invoice-notes p {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .table-invoice tbody > tr > th:first-child,
.dark-theme .table-invoice tbody > tr > td:first-child {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .billed-from p,
.dark-theme .billed-to p {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme .card-invoice .tx-gray-600 {
  color: #eef0f3;
}

.dark-theme .billed-to h6 {
  color: #d3d8e2;
}

.dark-theme .invoice-info-row + .invoice-info-row {
  border-top: 1px dotted rgba(226, 232, 245, 0.15);
}

.dark-theme .invoice-info-row span:first-child {
  color: rgba(255, 255, 255, 0.3);
}

.dark-theme .main-invoice-list {
  border-top: 1px solid #484863;
}

.dark-theme .card-category {
  background: rgba(239, 242, 246, 0.1);
}

.dark-theme .pricing-card .list-unstyled li {
  border-bottom: 1px solid rgba(234, 237, 241, 0.1);
}

.dark-theme .price.panel-color > .panel-body,
.dark-theme .price .panel-footer {
  background-color: rgb(38, 50, 78);
}

.dark-theme .pricing .list-unstyled li {
  border-bottom: 1px solid rgba(234, 237, 241, 0.1);
}

.dark-theme .card--events .list-group-item h6 {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .rdiobox span:before {
  background-color: #141b2d;
  border: 1px solid #4a5677;
}

.dark-theme .colorinput-color {
  border: 1px solid rgba(234, 240, 247, 0.2);
}

.dark-theme .nice-select .list {
  background-color: #2a2e3f;
  -webkit-box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
  box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
}

.dark-theme .nice-select .option:hover,
.dark-theme .nice-select .option.focus,
.dark-theme .nice-select .option.selected.focus {
  background-color: rgba(237, 239, 245, 0.1);
}

.dark-theme .item-card .cardtitle a {
  color: #fefefe;
}

.dark-theme .item-card .cardprice span {
  color: #dfe5ec;
}

.dark-theme .bd-b {
  border-bottom: 1px solid #484863;
}

.dark-theme .bd-r {
  border-right: 1px solid #484863;
}

.dark-theme .bd-t {
  border-top: 1px solid #484863;
}

.dark-theme .bd-l {
  border-left: 1px solid #484863;
}

.dark-theme .bd-y {
  border-top: 1px solid #484863;
  border-bottom: 1px solid #484863;
}

.dark-theme .bd-x {
  border-left: 1px solid #484863;
  border-right: 1px solid #484863;
}

.dark-theme .bg-gray-500 {
  background-color: rgba(151, 163, 185, 0.2);
}

.dark-theme .bg-gray-400 {
  background-color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-card-signin {
  background-color: transparent;
  border: 0px solid rgba(227, 227, 227, 0.1);
}

.dark-theme .main-signin-header h4 {
  color: #fff;
}

.dark-theme .main-signin-footer a {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .main-signup-footer a {
  color: #fff;
}

.dark-theme .construction .btn.btn-icon {
  border: 1px solid rgba(221, 230, 241, 0.1);
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}

.dark-theme .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #3b4563 !important;
}

.dark-theme .horizontalMenucontainer .desktop-logo,
.dark-theme .horizontalMenucontainer .desktop-logo-dark {
  display: none;
}

.dark-theme .desktop-dark {
  display: block !important;
}

.dark-theme.sidenav-toggled .desktop-dark {
  /*   display: none !important; */
}

.dark-theme.sidenav-toggled-open .desktop-dark {
  display: block !important;
}

@media (min-width: 992px) {
  .dark-theme.sidebar-mini.sidenav-toggled
    .main-sidebar-header
    .icon-light
    .logo-icon {
    display: none;
    height: 2.5rem;
  }

  .dark-theme.sidebar-mini.sidenav-toggled
    .main-sidebar-header
    .icon-dark
    .logo-icon.dark-theme {
    display: block;
    height: 2.5rem;
  }

  .dark-theme.sidebar-mini.sidenav-toggled.sidenav-toggled1
    .main-sidebar-header
    .logo-light
    .main-logo {
    display: none;
  }
}

@media (max-width: 991px) and (min-width: 568px) {
  .dark-theme .horizontalMenucontainer .desktop-dark {
    margin-left: 2rem !important;
  }
}

@media (max-width: 598px) {
  .dark-theme .horizontalMenucontainer .desktop-logo-dark {
    display: none;
    margin-left: 2.5rem;
    height: 2.5rem;
  }

  .dark-theme .main-header-left .header-brand {
    top: 12px;
  }
}

@media (max-width: 991px) {
  .dark-theme .animated-arrow span {
    background: #fff;
  }

  .dark-theme .animated-arrow span:before,
  .dark-theme .animated-arrow span:after {
    background: #fff;
  }

  .dark-theme.active .animated-arrow span {
    background-color: transparent;
  }
}

.dark-theme .sidebar {
  background: #2a2e3f;
}

.dark-theme .main-calendar.fc-list-empty {
  background-color: #2a2e3f;
  border: 1px solid #141b2d;
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar button.fc-month-button::before,
  .dark-theme
    .main-calendar
    .fc-header-toolbar
    button.fc-agendaWeek-button::before,
  .dark-theme
    .main-calendar
    .fc-header-toolbar
    button.fc-agendaDay-button::before,
  .dark-theme
    .main-calendar
    .fc-header-toolbar
    button.fc-listWeek-button::before,
  .dark-theme
    .main-calendar
    .fc-header-toolbar
    button.fc-listMonth-button::before {
    color: #fff;
  }
}

.dark-theme .breadcrumb {
  background-color: #484863;
}

.dark-theme table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid rgba(239, 239, 239, 0.1);
}

.dark-theme
  table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child::before {
  background-color: var(--primary-bg-color);
}

/*----- Horizontal-menu -----*/
.dark-theme .horizontal-main.hor-menu {
  background: #2a2e3f;
  border-bottom: 1px solid rgba(213, 216, 226, 0.1);
  border-top: 0;
  box-shadow: 0 0.15rem 1.75rem 0 #0e0f2e;
}

.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active {
  color: var(--primary-bg-color);
  background: transparent;
}

.dark-theme .horizontalMenu > .horizontalMenu-list > li > a {
  color: #bfc8de;
}

.dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
  color: var(--primary-bg-color);
  background: transparent;
}

.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
  border: 1px solid rgba(231, 234, 243, 0.1);
}

.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme .sub-menu li a:before {
  border-color: rgba(255, 255, 255, 0.5);
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  .sub-menu-sub:after {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu {
  background-color: #2a2e3f;
  box-shadow: 0 8px 16px 0 rgba(8, 13, 25, 0.4);
  border: none;
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a:hover {
  color: var(--primary-bg-color) !important;
}

.dark-theme .mega-menubg {
  background: #2a2e3f;
  box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
  border: 1px solid rgba(231, 234, 243, 0.1);
}

.dark-theme
  .hor-menu
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li
  a {
  background: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .mega-menubg.hor-mega-menu h3 {
  color: #fff;
}

.dark-theme .main-profile-menu .dropdown-item + .dropdown-item {
  border-top: 1px solid rgba(226, 234, 249, 0.2);
}

.dark-theme .rating-scroll h6 {
  color: #fff;
}

.dark-theme .latest-tasks .nav-link.active,
.dark-theme .latest-tasks .nav-link:hover,
.dark-theme .latest-tasks .nav-link:focus {
  background: transparent;
  color: var(--primary-bg-color);
}

.dark-theme .main-calendar .fc-list-empty {
  background-color: #2a2e3f;
  border: 1px solid #384361;
}

.dark-theme .card.bg-info-transparent {
  background: rgba(23, 162, 184, 0.2) !important;
}

.dark-theme .form-control::placeholder {
  color: rgba(212, 218, 236, 0.4) !important;
  opacity: 0.5;
}

.dark-theme .header-icon,
.dark-theme .main-header-message .nav-link i,
.dark-theme .main-header-notification .nav-link i,
.dark-theme .nav-item.full-screen .nav-link i,
.dark-theme .sales-flot .flot-chart .flot-x-axis > div span:last-child,
.dark-theme .p-text .p-name {
  color: #a9abbd;
}

.dark-theme
  .hor-menu
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > a.active {
  color: var(--primary-bg-color);
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > a:hover {
  color: #696af1;
  color: var(--primary-bg-color);
}

.dark-theme
  .hor-menu
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > .horizontal-megamenu
  .link-list
  li
  a.active {
  color: var(--primary-bg-color) !important;
}

.dark-theme .sidebar .tab-menu-heading {
  background: rgba(255, 255, 255, 0.05);
}

.dark-theme .sidebar .tabs-menu ul li a {
  border: 1px solid rgba(220, 231, 245, 0.1);
  background: var(--primary02);
  color: #fff;
}

.dark-theme .sidebar .tabs-menu ul li .active {
  background: var(--primary-bg-color);
  color: #fff;
  border: 1px solid var(--primary-bg-color);
}

.dark-theme .datepicker > .datepicker_inner_container > .datepicker_calendar,
.dark-theme .datepicker > .datepicker_inner_container > .datepicker_timelist {
  background-color: #2a2e3f;
}

.dark-theme .datepicker > .datepicker_header {
  background-color: #2a2e3f;
  color: #fff;
}

.dark-theme .main-datetimepicker {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme
  .main-datetimepicker
  > .datepicker_inner_container
  > .datepicker_calendar
  th {
  color: #fcfdff;
}

.dark-theme
  .main-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme
  .main-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item:hover,
.dark-theme
  .main-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item:focus {
  background-color: #323850;
}

.dark-theme
  .datepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item.hover {
  color: #fff;
  background-color: #26324c;
}

.dark-theme .datetimepicker .datetimepicker-days table thead tr:last-child th {
  color: #fff;
}

.dark-theme .datetimepicker table th.dow {
  background: #2a2e3f;
}

.dark-theme .datetimepicker table td.old {
  color: #7a82af;
}

@media (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list {
    background: #2a2e3f;
  }

  .dark-theme .horizontalMenu > .horizontalMenu-list > li > a {
    border-bottom-color: rgba(231, 234, 243, 0.1);
  }

  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    border-radius: 0;
  }

  .dark-theme
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li:hover
    .sub-icon {
    color: var(--primary-bg-color);
    background: #2a2e3f;
    border-bottom: 1px solid rgba(231, 234, 243, 0.1);
  }

  .dark-theme
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li:hover
    .side-menu__icon {
    fill: #7c52ca;
  }

  .dark-theme
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    li:hover
    > a {
    background-color: transparent;
    color: #7c52ca !important;
  }

  .dark-theme
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > a:hover:before {
    border-color: #eef0f7;
  }

  .dark-theme
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    > li
    > ul.sub-menu
    > li
    > a:hover {
    color: #fff !important;
    background-color: rgba(231, 231, 231, 0.1);
  }

  .dark-theme
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li:hover
    a:before {
    border-color: #eef0f7 !important;
    color: #fff !important;
  }

  .dark-theme .mega-menubg li a:before {
    border-color: #8594ad;
  }

  .dark-theme
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > ul.sub-menu
    .sub-menu-sub:after {
    display: none;
  }

  .dark-theme .mega-menubg {
    background: #2a2e3f !important;
  }

  .dark-theme
    .hor-menu
    .horizontalMenu
    > .horizontalMenu-list
    > li
    > .horizontal-megamenu
    .link-list
    li
    a {
    background: #2a2e3f !important;
    color: #8291af !important;
  }
}

@media (min-width: 578px) {
  .dark-theme .desktop-logo.logo-light .main-logo {
    display: none;
  }

  .dark-theme .logo-icon.mobile-logo.icon-light .logo-icon {
    display: none !important;
  }

  .dark-theme .logo-icon.mobile-logo.icon-light .logo-icon.dark-theme {
    display: none !important;
  }

  .dark-theme .desktop-logo.logo-dark .main-logo.dark-theme {
    display: block !important;
  }

  .dark-theme.sidenav-toggled .desktop-logo.logo-dark .main-logo.dark-theme {
    display: none !important;
  }
}

@media (max-width: 578px) {
  .dark-theme .desktop-logo.logo-light .main-logo {
    display: none;
  }

  .dark-theme .logo-icon.mobile-logo.icon-light {
    display: none !important;
  }

  .dark-theme .logo-icon.mobile-logo.icon-light .logo-icon.dark-theme {
    display: block !important;
  }

  .dark-theme .logo-icon.mobile-logo.icon-dark {
    display: block !important;
  }
}

.dark-theme.sidenav-toggled.sidenav-toggled-open
  .desktop-logo.logo-dark
  .main-logo.dark-theme {
  display: block !important;
}

.dark-theme.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .desktop-logo.logo-dark {
  display: block !important;
}

.datetimepicker table td.new {
  color: #7a82af;
}

.dark-theme .datetimepicker table td {
  background: transparent;
  color: #fff;
}

.dark-theme .datetimepicker table th.prev {
  background-color: transparent;
  color: #fff;
}

.dark-theme .datetimepicker table th.next,
.dark-theme .datetimepicker table th.switch {
  background-color: transparent;
  color: #fff;
}

.dark-theme .datetimepicker table th.prev:hover,
.datetimepicker table th.prev:focus {
  background-color: #2a2e3f;
}

.dark-theme .datetimepicker table th.next:hover,
.dark-theme .datetimepicker table th.next:focus {
  background-color: #2a2e3f;
}

.dark-theme .datetimepicker table th.prev span::before,
.dark-theme .datetimepicker table th.next span::before {
  color: #fff;
}

.dark-theme .datetimepicker table th.switch:hover {
  background-color: #2a2e3f;
  color: #5965f9;
}

.datetimepicker table th.switch:focus {
  background-color: #2a2e3f;
  color: #5965f9;
}

.dark-theme
  .main-datetimepicker
  > .datepicker_inner_container
  > .datepicker_calendar
  td.hover {
  background-color: var(--primary-bg-color);
}

.dark-theme .iti__selected-flag {
  border-right: 1px solid rgba(225, 230, 241, 0.1);
}

.dark-theme
  .iti--allow-dropdown
  .iti__flag-container:hover
  .iti__selected-flag {
  background-color: #2a2e3f;
}

.dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
  background: #2a2e3f;
}

.dark-theme .dashboard-carousel .btn-icons {
  background: rgba(239, 242, 246, 0.2) !important;
  color: #fff;
}

.dark-theme .slide.dashboard-carousel:hover {
  background: transparent;
}

.dark-theme .btn-light:focus,
.dark-theme .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
}

.dark-theme .btn-light:not(:disabled):not(.disabled):active,
.dark-theme .btn-light:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #484863;
  border-color: rgba(189, 198, 214, 0.2);
}

.dark-theme .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #484863;
  border-color: rgba(189, 198, 214, 0.2);
}

.dark-theme .modal-dialog {
  box-shadow: none;
}

.dark-theme .email-media .media-body small {
  color: rgb(255, 255, 255);
}

.dark-theme .email-media .media-body .media-title {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

.dark-theme .page-item.disabled .page-link {
  color: #fff;
  background: #141b2d;
}

.dark-theme .demo-gallery .pagination {
  border: 0;
}

.dark-theme .chat .msg_cotainer,
.dark-theme .chat .msg_cotainer_send {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .chat .dropdown-menu li {
  padding: 7px;
  color: #dde2e8;
}

.dark-theme .chat .dropdown-menu li:hover {
  color: var(--primary-bg-color);
  background: rgba(238, 238, 247, 0.06);
}

.dark-theme .latest-tasks .tasks .span {
  color: #fff;
}

.dark-theme .list-group-item-action {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .list-group-item.active {
  color: #fff;
}

.dark-theme .list-group-item-success {
  color: #1f5c01;
  background-color: #c8e9b8;
}

.dark-theme .list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.dark-theme .list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.dark-theme .list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.dark-theme .bg-danger {
  background-color: #f34343 !important;
}

.dark-theme .bg-primary {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .bg-secondary {
  background-color: #f74f75 !important;
}

.dark-theme .bg-gray-800 {
  background-color: var(--primary02) !important;
}

.dark-theme .bg-success {
  background-color: #24d5b8 !important;
}

.dark-theme .bg-info p.card-text {
  color: #000;
}

.dark-theme .main-content-body.main-content-body-contacts .media-body h5 {
  color: #fff;
}

.dark-theme .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #2a2e3f;
}

.dark-theme .dataTables_wrapper .dataTables_filter input {
  color: #fff;
}

.dark-theme .dataTables_wrapper .dataTables_filter input::placeholder {
  color: rgba(212, 218, 236, 0.4) !important;
}

.dark-theme .dataTables_paginate .page-link {
  background: transparent;
}

.dark-theme .iti__divider {
  border-bottom: 1px solid rgba(189, 198, 214, 0.2);
}

.dark-theme .iti__country.iti__highlight {
  background-color: #2a2e3f;
}

.dark-theme .iti__country-list {
  background-color: #2a2e3f;
  box-shadow: 0 8px 16px 0 rgb(44, 50, 82);
}

.dark-theme
  .iti--allow-dropdown
  .iti__flag-container:hover
  .iti__selected-flag {
  background-color: #414565;
}

.dark-theme .price.panel-color > .panel-body {
  background-color: #1f2940;
  border: 1px solid rgba(234, 237, 241, 0.1);
  border-bottom: 0;
  border-top: 0;
}

.dark-theme .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  background-color: transparent;
  color: #fff;
}

.dark-theme
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:hover,
.dark-theme
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.disabled:focus {
  background-color: transparent;
  color: #fff;
}

.dark-theme .main-nav-line .nav-link.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-theme .datetimepicker table {
  background: transparent;
}

.dark-theme .dataTables_paginate .pagination .page-link {
  border: 1px solid rgba(205, 215, 239, 0.15);
}

@media only screen and (max-width: 991px) {
  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background-color: #2a2e3f;
    border: none;
  }

  .dark-theme .main-header {
    /*     box-shadow: 0 0 10px rgba(5, 6, 9, 0.8) !important; */
  }
}

@media only screen and (min-width: 992px) {
  .dark-theme .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: var(--primary-bg-color);
    background: transparent;
  }

  .dark-theme .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    background: #2a2e3f;
    box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
  }

  .dark-theme .main-header {
    background: #2a2e3f;
    border-bottom: 1px solid #ededf5;
    box-shadow: none;
  }

  .dark-theme.horizontal .main-sidemenu .slide-left,
  .dark-theme.horizontal .main-sidemenu .slide-right {
    background-color: #2a2e3f;
  }
}

@media (min-width: 992px) {
  .dark-theme .main-content-left-contacts {
    border-right: 1px solid #484863;
  }
}

@media (min-width: 576px) {
  .dark-theme .main-calendar .fc-header-toolbar button {
    color: #fff;
  }
}

@media (max-width: 575px) {
  .dark-theme .main-calendar .fc-header-toolbar .fc-left button {
    color: #fff;
  }

  .dark-theme
    .main-calendar
    .fc-header-toolbar
    .fc-left
    button.fc-today-button {
    color: #000;
  }
}

@media (max-width: 605px) {
  .dark-theme .fc-view,
  .fc-view > table {
    border: 1px solid rgba(226, 232, 245, 0.2);
  }
}

.dark-theme .chat abbr.timestamp,
.dark-theme .chat .msg_time,
.dark-theme .chat .msg_time_send {
  color: #fff;
}

.dark-theme .nav-tabs {
  border-bottom: 1px solid rgba(226, 232, 245, 0.2);
}

.dark-theme .nav-tabs .nav-link.active {
  border-color: rgba(226, 232, 245, 0.2);
}

.dark-theme .page-link {
  border: 1px solid rgba(226, 232, 245, 0.2);
}

.dark-theme .popover-head-primary .popover-header {
  color: #fff !important;
  background-color: var(--primary-bg-color);
}

.dark-theme .popover-head-secondary .popover-header {
  color: #fff !important;
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .popover-head-secondary.bs-popover-bottom .arrow::after,
.dark-theme
  .popover-head-secondary.bs-popover-auto[data-popper-placement^="bottom"]
  .arrow::after {
  border-bottom-color: var(--primary-bg-color) !important;
}

.dark-theme .popover-primary .popover-header,
.dark-theme .popover-secondary .popover-header {
  background-color: transparent !important;
  color: #fff !important;
}

.dark-theme .popover-primary.bs-popover-top .arrow::after,
.dark-theme
  .popover-primary.bs-popover-auto[data-popper-placement^="top"]
  .arrow::after {
  border-top-color: var(--primary-bg-color) !important;
}

.dark-theme .popover-secondary.bs-popover-bottom .arrow::after,
.dark-theme
  .popover-secondary.bs-popover-auto[data-popper-placement^="bottom"]
  .arrow::after {
  border-bottom-color: var(--primary-bg-color) !important;
}

.dark-theme .gray-set .bg-transparent {
  background-color: transparent !important;
}

.dark-theme .gray-set .bg-gray-100 {
  background-color: #f9f9fb !important;
}

.dark-theme .gray-set .bg-gray-200 {
  background-color: #f0f0f8 !important;
}

.dark-theme .gray-set .bg-gray-300 {
  background-color: #e1e1ef !important;
}

.dark-theme .gray-set .bg-gray-400 {
  background-color: #d6d6e6 !important;
}

.dark-theme .gray-set .bg-gray-500 {
  background-color: #949eb7 !important;
}

.dark-theme .gray-set .bg-gray-600 {
  background-color: #7987a1 !important;
}

.dark-theme .gray-set .bg-gray-700 {
  background-color: #4d5875 !important;
}

.dark-theme .gray-set .bg-gray-800 {
  background-color: #383853 !important;
}

.dark-theme .gray-set .bg-gray-900 {
  background-color: #323251 !important;
}

.dark-theme .main-card-signin {
  box-shadow: none;
}

.dark-theme .page-item.disabled .page-link {
  border-color: #4c576f;
  background: #2a2e3f;
}

.dark-theme .ps > .ps__rail-y {
  background-color: transparent;
}

.dark-theme .app-sidebar .ps > .ps__rail-y {
  background-color: transparent;
}

.dark-theme .slide.is-expanded .side-menu__icon,
.dark-theme .slide.is-expanded .angle {
  color: var(--primary-bg-color) !important;
}

.dark-theme .close-toggle {
  color: #fff !important;
}

.dark-theme .slide.is-expanded .side-menu__label,
.dark-theme .slide.is-expanded .side-menu__icon,
.dark-theme .slide.is-expanded .angle {
  color: #a9abbd !important;
}

.dark-theme .slide-item.active,
.dark-theme .slide-item:hover,
.dark-theme .slide-item:focus {
  color: var(--primary-bg-color) !important;
}

.dark-theme .side-menu .slide.active .side-menu__label,
.dark-theme .side-menu .slide.active .side-menu__icon {
  color: var(--primary-bg-color) !important;
}

.dark-theme .slide:hover .side-menu__label,
.dark-theme .slide:hover .angle,
.dark-theme .slide:hover .side-menu__icon {
  color: var(--primary-bg-color);
}

.dark-theme .card.bg-primary-gradient {
  background-image: linear-gradient(
    to left,
    var(--primary-bg-color) 0%,
    var(--primary05) 100%
  ) !important;
}

.dark-theme .card.bg-secondary-gradient {
  background-image: linear-gradient(
    to right,
    #f714c2 0%,
    #b9309a 100%
  ) !important;
}

.dark-theme .card.bg-success-gradient {
  background-image: linear-gradient(
    to left,
    #029666 0%,
    #4ec752 100%
  ) !important;
}

.dark-theme .card.bg-warning-gradient {
  background-image: linear-gradient(
    to left,
    #f76a2d,
    rgba(251, 140, 18, 0.9294117647)
  ) !important;
}

.dark-theme .vmap-wrapper {
  background: #2a2e3f !important;
}

.dark-theme .card-dashboard-eight .list-group-item span {
  color: #f1f4f9;
}

.dark-theme .card-dashboard-eight .list-group-item {
  background-color: #1f2940;
  border-bottom: 1px solid rgba(231, 235, 243, 0.1);
  border-color: #484863;
}

.dark-theme .sales-info h3,
.dark-theme .card-table h4 {
  color: #f0f3f9;
}

.dark-theme .total-revenue h4 {
  color: #fcfdff;
}

.dark-theme .product-timeline ul.timeline-1:before {
  border-left: 2px dotted rgba(192, 204, 218, 0.3);
}

.dark-theme .main-dashboard-header-right > div h5 {
  color: #fff;
}

.dark-theme .customers .list-group-item-action:hover,
.dark-theme .customers .list-group-item-action:focus {
  color: #0160e4;
  background-color: #2a2e3f;
}

.dark-theme .customers h5 {
  color: #fff;
}

.dark-theme .side-menu .slide .side-menu__item.active {
  background-color: transparent;
}

.dark-theme nav.prod-cat li a {
  color: rgb(176, 187, 204);
}

.dark-theme .product-sale .wishlist {
  color: #000;
}

.dark-theme .nav-tabs.preview-thumbnail {
  border-bottom: 0;
}

.dark-theme .select2-dropdown {
  border-color: rgba(208, 215, 232, 0.1);
}

.dark-theme .bd-2 {
  border-width: 2px !important;
}

.dark-theme .sidebar-right .list a {
  color: rgba(247, 248, 251, 0.7);
}

.dark-theme .card-footer {
  background-color: #2a2e3f;
}

.dark-theme .card.card-primary {
  border-top: 2px solid var(--primary-bg-color) !important;
}

.dark-theme .card.card-secondary {
  border-top: 2px solid #7987a1 !important;
}

.dark-theme .card.card-success {
  border-top: 2px solid #22e840 !important;
}

.dark-theme .card.card-danger {
  border-top: 2px solid #ee335e !important;
}

.dark-theme .card.card-warning {
  border-top: 2px solid #ffb209 !important;
}

.dark-theme .card.card-info {
  border-top: 2px solid #01b8ff !important;
}

.dark-theme .card.card-purple {
  border-top: 2px solid #673ab7 !important;
}

.dark-theme .card.card-dark {
  border-top: 2px solid #343a40 !important;
}

.dark-theme .nav-tabs.html-source {
  border-bottom: 0;
}

.dark-theme .nav-tabs.html-source .nav-link.active {
  border-color: rgb(20, 27, 45);
  background-color: #141b2d;
}

.dark-theme .toast-body {
  padding: 0.75rem;
  background: #2a2e3f;
  color: #b8bfc9;
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link {
  background: #2a2e3f;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link {
  background: #2a2e3f !important;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link.active {
  background: #2a2e3f !important;
}

.dark-theme .tabs-style-2 .main-nav-line .nav-link {
  border: 1px solid #484863;
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a {
  background: #2a2e3f;
  color: #fff;
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a {
  background: #2a2e3f;
  color: #fff;
}

.dark-theme .nav-link.html-code {
  background: #1f2940;
}

.dark-theme .nav-tabs .nav-link.html-code.active,
.dark-theme .nav-tabs .nav-link.html-code:hover,
.dark-theme .nav-tabs .nav-link.html-code:focus {
  background-color: #141b2d;
  color: #fff;
}

.dark-theme .nav-tabs .nav-link.html-code:hover,
.dark-theme .nav-tabs .nav-link.html-code:focus {
  border: 0;
}

.dark-theme .card .box {
  box-shadow: 0 0 25px #2a2e3f;
  border: 0;
}

.dark-theme .userlist-table .user-link {
  color: #fbf5f5;
}

.dark-theme
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  background-color: var(--primary-bg-color);
}

.dark-theme .main-chat-body .content-inner:before {
  background: rgba(15, 26, 51, 0.93);
}

.dark-theme .left.main-msg-wrapper:before {
  color: #343e53;
}

.dark-theme .icons-list-item {
  border: 1px solid rgba(214, 220, 236, 0.15);
  box-shadow: 0px 1px 6px 0px #1d1e20;
  color: #a9abbd !important;
  fill: #a9abbd !important;
}

.dark-theme .user-wideget-footer {
  background-color: #2a2e3f;
}

.dark-theme .profile.navtab-custom .active a {
  background: #273350;
  border-bottom: 0;
  color: #fffafa;
}

.dark-theme .profile.navtab-custom li a {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(227, 230, 240, 0.1);
}

.dark-theme .profile.navtab-custom a.active {
  background: #273350;
}

.dark-theme .nav.prod-cat li a {
  color: #fff;
}

.dark-theme .prod-cat li ul li.active a {
  background: none;
  color: #ee335e;
}

.dark-theme .header-icon-svgs {
  fill: #a9abbd;
  color: #a9abbd;
}

.dark-theme .app-sidebar .side-item.side-item-category {
  color: #c5cfcd;
}

.dark-theme .product-pagination .page-item.disabled .page-link {
  border-color: #3c465d;
  background: #383d52 !important;
  color: #555d6f;
}

.dark-theme .price span {
  color: #ebedf1;
}

.dark-theme #list3 .media-body h6 {
  color: rgb(255, 255, 255);
}

.dark-theme #list3 .list-group-item,
.dark-theme #list8 .list-group-item,
.dark-theme #list1 .list-group-item,
.dark-theme #list8 .list-group-item {
  border: 1px solid rgba(231, 235, 243, 0.1);
}

.dark-theme .bg-gray-100.nav-bg .nav-tabs {
  border-bottom: 1px solid rgb(45, 54, 75);
}

.dark-theme .bg-gray-100.nav-bg .nav-tabs .nav-link {
  background-color: var(--primary02);
}

.dark-theme .popover-static-demo .popover {
  box-shadow: -8px 12px 18px 0 #141b2d;
  border: 1px solid #3d4152;
}

.dark-theme .heading-inverse {
  background-color: #141b2c;
}

.dark-theme .toast {
  box-shadow: -8px 12px 18px 0 rgba(24, 32, 51, 0.0588235294);
}

.dark-theme .tabs-style-1 .dark-theme .border {
  border: 1px solid rgba(234, 236, 241, 0.1) !important;
}

.dark-theme .tabs-style-1 .dark-theme .border-top-0 {
  border-top: 0 !important;
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link.active {
  color: #f3f5f9;
  border: 1px solid;
  border-color: rgba(224, 230, 237, 0.1) rgba(224, 230, 237, 0.1) #1f2940;
  border-bottom-color: transparent !important;
}

.dark-theme .tabs-style-1 .panel-tabs {
  border-bottom: 0;
}

.dataTables_paginate .page-item.previous a {
  width: 77px;
}

.dark-theme.app.sidenav-toggled.sidenav-toggled-open .side-menu__label {
  color: #a9abbd;
}

.dark-theme.app.sidenav-toggled.sidenav-toggled-open
  .side-menu__item.active
  .side-menu__label {
  color: #a9abbd;
}

@media (min-width: 992px) {
  .dark-theme.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
    .desktop-logo {
    display: none !important;
  }

  .dark-theme.leftbgimage1 .main-sidebar-header,
  .dark-theme.leftbgimage2 .main-sidebar-header,
  .dark-theme.leftbgimage3 .main-sidebar-header,
  .dark-theme.leftbgimage4 .main-sidebar-header,
  .dark-theme.leftbgimage5 .main-sidebar-header {
    border-right: 1px solid #242e45;
    width: 240px;
  }
}

@media (max-width: 1199px) {
  .dark-theme .total-revenue {
    border-bottom: 1px solid rgba(227, 232, 247, 0.1) !important;
  }
}

.dark-theme .horizontalMenucontainer .side-menu__icon {
  color: #bfc8de;
  fill: #bfc8de;
}

.dark-theme.horizontal-light .horizontalMenucontainer .side-menu__icon {
  color: #5b6e88;
  fill: #5b6e88;
}

.dark-theme .main-header .dropdown-menu-start {
  box-shadow: 0px 0px 15px 1px #041138;
}

.dark-theme .main-header .dropdown.nav-itemd-none .dropdown-menu:after {
  border-bottom: 9px solid #1f2940;
}

@media (min-width: 992px) {
  .dark-theme .top-header .header-brand.header-brand2 .desktop-dark {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
}

.dark-theme .app-sidebar.toggle-sidemenu {
  border-top: 1px solid #484863;
}

.dark-theme .first-sidemenu {
  background: #1f2940;
}

.dark-theme .second-sidemenu {
  background: #1f2940;
  border-right: 1px solid #484863;
}

.dark-theme .resp-vtabs .resp-tabs-list li {
  border: 1px solid #484863 !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0;
}

.dark-theme .first-sidemenu li.active,
.dark-theme .resp-vtabs .resp-tab-active:hover {
  background: #1f2940;
  border-right: 0 !important;
}

.dark-theme .resp-vtabs li.resp-tab-active {
  border-right: 0 !important;
}

.dark-theme .first-sidemenu .side-menu__icon,
.dark-theme .toggle-sidemenu .slide-item {
  color: #a9abbd;
  fill: #a9abbd;
}

.dark-theme .second-sidemenu h5 {
  color: #dde3ea;
}

.dark-theme.app.sidebar-mini.sidenav-toggled .first-sidemenu li.active {
  border-right: 1px solid #484863 !important;
}

.dark-theme .file-browser .btn-default {
  background: #3e405e;
  color: #fff;
  border: 1px solid rgba(226, 232, 245, 0.1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dark-theme .card.bg-purple-gradient {
  background-image: linear-gradient(
    to right,
    #673ab7 0%,
    #884af1 100%
  ) !important;
}

.dark-theme .fc-datepicker.main-datepicker.hasDatepicker {
  border: 1px solid #333d52;
}

.dark-theme .ql-snow .ql-picker:hover .ql-picker-label,
.dark-theme .ql-snow .ql-picker:focus .ql-picker-label {
  color: #fff;
}

.dark-theme .ql-snow .ql-picker:hover .ql-picker-label,
.dark-theme .ql-snow .ql-picker:focus .ql-picker-label {
  color: #fff;
}

.dark-theme .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #2c3242;
}

.dark-theme.hoversubmenu .side-menu__item.active .side-menu__icon {
  fill: #106ef1;
}

@media (min-width: 992px) {
  .dark-theme.hoversubmenu.style1-leftmenu.sidenav-toggled
    .app-sidebar
    .side-menu-label1 {
    border-bottom: 1px solid rgba(225, 230, 241, 0.1);
    color: #fff !important;
  }
}

@media (min-width: 992px) {
  .dark-theme.app.sidebar-mini.sidenav-toggled-open
    .ps
    > .ps__rail-y
    > .ps__thumb-y {
    background-color: #1f2940;
  }
}

.dark-theme .app-sidebar__toggle {
  background: transparent;
}

.dark-theme .header-icons .new.nav-link {
  background: transparent;
}

.dark-theme .main-profile-menu .profile-user img {
  background: #141432;
  box-shadow: 0px 1px 6px 0px #3b3b3b;
}

.dark-theme ul.timeline:before {
  background: #4e4e6b;
}

.dark-theme .apexcharts-radialbar-track.apexcharts-track path {
  stroke: #2a2e3f;
}

.dark-theme .apex-charts .apexcharts-datalabels-group text {
  fill: #fff;
}

.dark-theme .slide.is-expanded a {
  color: rgba(255, 255, 255, 0.6);
}

.dark-theme .featured_icon i {
  color: #bfbcc3;
}

.dark-theme .pro-img-box img {
  border: 1px solid #3d4152 !important;
}

.dark-theme .carousel-inner .carousel-item .thumb {
  background: #2a2e3f;
  border: 1px solid #484863;
}

.dark-theme .table-hover > tbody > tr:hover {
  --bs-table-accent-bg: $primary-02;
  color: #bfbcc3;
}

.dark-theme .handle-counter input {
  border: 1px solid #3c3c4b;
  background: #2a2e3f;
  color: #e3dcdc;
}

.dark-theme .product-details .media img {
  border: 1px solid #2a2e3f;
}

.dark-theme a {
  color: #faf9fa;
}

.dark-theme .btn a {
  color: #f2eeee;
}

.dark-theme .btn-list a {
  color: #f2eeee;
}

.dark-theme .fc .fc-non-business {
  background: #2a2e3f;
}

.dark-theme .fc .fc-daygrid-day.fc-day-today {
  background-color: #2a2e3f;
}

.dark-theme .fc-theme-standard .fc-scrollgrid {
  border: 0px solid #3d4152;
}

.dark-theme #external-events {
  border: 1px solid #2a2e3f;
  background: #383d52;
}

.dark-theme .fc .fc-list-empty {
  background-color: #323258;
}

.dark-theme #calendar2 .fc-scrollgrid.fc-scrollgrid-liquid thead tr th {
  border-bottom: 0;
}

.dark-theme .fc .fc-button-primary {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.dark-theme .main-contact-label {
  border-top: 1px solid #3d4152;
}

.dark-theme .contact-icon {
  box-shadow: 0px 1px 6px 0px #151b21;
}

.dark-theme .bg-dark-1 {
  background: #2a2e3f;
}

@media (min-width: 768px) {
  .dark-theme .main-nav .nav-link + .nav-link {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }

  .dark-theme.rtl .main-nav .nav-link + .nav-link {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 0;
  }
}

.dark-theme .pagination-dark.pagination .page-link {
  background-color: #2a2e3f !important;
  border: 1px solid #434364 !important;
}

.dark-theme .bs-popover-top > .popover-arrow::after,
.dark-theme
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow::after {
  border-top-color: #2a2e3f;
}

.dark-theme .bs-popover-bottom > .popover-arrow::after,
.dark-theme
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow::after {
  border-bottom-color: #2a2e3f;
}

.dark-theme .bs-popover-start > .popover-arrow::after,
.dark-theme
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow::after {
  border-left-color: #2a2e3f;
}

.dark-theme .bs-popover-end > .popover-arrow::after,
.dark-theme
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow::after {
  border-right-color: #2a2e3f;
}

.dark-theme .popover-secondary.bs-popover-top .popover-arrow::after,
.dark-theme
  .popover-secondary.bs-popover-auto[data-popper-placement^="top"]
  .popover-arrow::after {
  border-top-color: #bc289a;
}

.dark-theme .bd-example-row .row > .col,
.dark-theme .bd-example-row .row > [class^="col-"] {
  background-color: #484863;
  border: 1px solid rgb(74, 74, 104);
}

.dark-theme .vtimeline .timeline-wrapper .timeline-badge {
  border: 2px solid #3d4152;
}

.dark-theme .bg-danger-gradient {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}

.dark-theme .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: $dark-theme;
  color: rgba(255, 255, 255, 0.7);
}

.dark-theme .main-nav-column .nav-link.active {
  background: var(--primary02);
  color: var(--primary-bg-color);
}

.dark-theme .main-nav-column .nav-link i:not([class*=" tx-"]) {
  color: #c3c7d1;
}

.dark-theme .mail-option .chk-all,
.dark-theme .mail-option .btn-group a.btn {
  border: 1px solid var(--primary02);
}

.dark-theme .inbox-pagination a.np-btn {
  border: 1px solid var(--primary02);
}

.dark-theme .main-mail-compose-header {
  background-color: #2a2e3f;
  color: #fff;
}

.dark-theme .main-mail-compose-body {
  background-color: #181e37;
}

.dark-theme .main-mail-compose-body .form-group .form-label {
  margin: 0;
  color: #a2a2ae;
}

.dark-theme .main-mail-compose-body .form-group + .form-group {
  border-top: 1px dotted #595f6f;
}

.dark-theme .main-chat-header .nav-link {
  color: #a9abbd;
  border: solid 1px var(--primary02);
  background: #2a2e3f;
}

.dark-theme .main-chat-footer .nav-link,
.dark-theme .main-chat-footer .main-msg-send {
  color: #a9abbd;
  border: solid 1px var(--primary02);
  background: #2a2e3f;
}

.dark-theme .main-msg-wrapper.left:nth-child(1):after {
  border-right-color: #484863;
}

.dark-theme.rtl .main-msg-wrapper.left:nth-child(1):after {
  border-left-color: #484863 !important;
}

.dark-theme .datepicker table tr td span {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2a2e3f;
}

.dark-theme .datepicker td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #2a2e3f;
  color: rgba(255, 255, 255, 0.4);
}

.dark-theme .datepicker table tr td.day:hover,
.dark-theme .datepicker table tr td.day.focused {
  background: #2a2e3f;
}

.dark-theme .datepicker {
  background-color: #2a2e3f;
  border: 1px solid #3d4152;
}

.dark-theme .ql-snow.ql-toolbar {
  border-color: #3d4152;
  background: #2a2e3f;
}

.dark-theme .ql-scrolling-demo {
  background-color: #2a2e3f;
}

.dark-theme .ql-snow.ql-toolbar button.ql-active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #dee4f3;
  border-color: transparent;
}

.dark-theme div.dt-button-info {
  background-color: #2a2e3f;
  border: 2px solid #3d4152;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.dark-theme div.dt-button-info h2 {
  background-color: #2a2e3f;
  border: 2px solid #3d4152;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.dark-theme .border-end {
  border-right: 1px solid #3c3c60 !important;
}

.dark-theme .border-start {
  border-left: 1px solid #3c3c60 !important;
}

.dark-theme .profile-cover__img > .h3 {
  color: #d6d6d7;
}

.dark-theme .profile-cover__info .nav li {
  color: #d6d6d7;
}

.dark-theme .bd-e {
  border-color: #484863;
}

.dark-theme .card-sigin {
  background: #2a2e3f;
  color: #d6d6d7;
}

.dark-theme .bxl-instagram.tx-prime,
.dark-theme .bxl-linkedin.tx-prime,
.dark-theme .bxl-twitter.tx-prime,
.dark-theme .bxl-facebook.tx-prime {
  color: var(--primary-bg-color);
  background: #2a2e3f;
  border: 0px solid #dfd7e9;
  box-shadow: 0px 1px 6px 0px #424449;
}

.dark-theme .form-control {
  color: #c0c2c7;
  background-color: #2a2e3f;
  border: 1px solid #484863;
}

.dark-theme .breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before,
.dark-theme .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
.dark-theme .breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  color: #5f626c;
}

.dark-theme .btn:hover,
.dark-theme .sp-container button:hover {
  color: #faf9fa;
}

.dark-theme .custom-control-label::before {
  background-color: #141524;
  border: #3c4358 solid 1px;
}

.dark-theme .navbar-toggler {
  background: transparent;
  color: #faf9fa;
}

.dark-theme .nav-tabs .nav-link:hover,
.dark-theme .nav-tabs .nav-link:focus {
  border-color: #454570 #454570 #454570;
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu {
  background-color: #2a2e3f;
  border: 1px solid #323254;
}

.dark-theme
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > ul.sub-menu
  > li
  > a {
  color: rgba(255, 255, 255, 0.5) !important;
}

.dark-theme .btn-close {
  box-sizing: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  padding: 5px;
  line-height: 1;
  font-size: 24px;
  background-image: none;
  color: white;
}

.dark-theme .horizontalMenucontainer .main-header.hor-header {
  border-bottom: 1px solid #3c3f50;
}

.dark-theme .btn-light:not(:disabled):not(.disabled):active:focus,
.dark-theme .btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem #45456a;
}

.dark-theme #external-events p {
  color: rgba(255, 255, 255, 0.5411764706);
}

.dark-theme .datepicker th {
  color: #d9d9d9;
}

.dark-theme .datepicker table tr td.old,
.dark-theme .datepicker table tr td.new {
  opacity: 0.9;
}

.dark-theme .datepicker table tr td span.active:hover,
.dark-theme .datepicker table tr td span.active:hover:hover,
.dark-theme .datepicker table tr td span.active.disabled:hover,
.dark-theme .datepicker table tr td span.active.disabled:hover:hover,
.dark-theme .datepicker table tr td span.active:active,
.dark-theme .datepicker table tr td span.active:hover:active,
.dark-theme .datepicker table tr td span.active.disabled:active,
.dark-theme .datepicker table tr td span.active.disabled:hover:active,
.dark-theme .datepicker table tr td span.active.active,
.dark-theme .datepicker table tr td span.active:hover.active,
.dark-theme .datepicker table tr td span.active.disabled.active,
.dark-theme .datepicker table tr td span.active.disabled:hover.active,
.dark-theme .datepicker table tr td span.active.disabled,
.dark-theme .datepicker table tr td span.active:hover.disabled,
.dark-theme .datepicker table tr td span.active.disabled.disabled,
.dark-theme .datepicker table tr td span.active.disabled:hover.disabled,
.dark-theme .datepicker table tr td span.active[disabled],
.dark-theme .datepicker table tr td span.active:hover[disabled],
.dark-theme .datepicker table tr td span.active.disabled[disabled],
.dark-theme .datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #666886;
}

.dark-theme .datepicker table tr td.active:hover,
.dark-theme .datepicker table tr td.active:hover:hover,
.dark-theme .datepicker table tr td.active.disabled:hover,
.dark-theme .datepicker table tr td.active.disabled:hover:hover,
.dark-theme .datepicker table tr td.active:active,
.dark-theme .datepicker table tr td.active:hover:active,
.dark-theme .datepicker table tr td.active.disabled:active,
.dark-theme .datepicker table tr td.active.disabled:hover:active,
.dark-theme .datepicker table tr td.active.active,
.dark-theme .datepicker table tr td.active:hover.active,
.dark-theme .datepicker table tr td.active.disabled.active,
.dark-theme .datepicker table tr td.active.disabled:hover.active,
.dark-theme .datepicker table tr td.active.disabled,
.dark-theme .datepicker table tr td.active:hover.disabled,
.dark-theme .datepicker table tr td.active.disabled.disabled,
.dark-theme .datepicker table tr td.active.disabled:hover.disabled,
.dark-theme .datepicker table tr td.active[disabled],
.dark-theme .datepicker table tr td.active:hover[disabled],
.dark-theme .datepicker table tr td.active.disabled[disabled],
.dark-theme .datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #666886;
}

.dark-theme input:focus-visible {
  outline-offset: #2e3954;
}

.dark-theme .main-nav .nav-link + .nav-link {
  border-top: 0px solid #3d4152;
}

.dark-theme .text-dark {
  color: #fcfcfc !important;
}

.dark-theme .nav-link.icon i {
  color: #a9abbd !important;
}

@media (max-width: 991.98px) {
  .dark-theme .responsive-navbar.navbar .navbar-collapse {
    background: #2a2e3f;
    box-shadow: 5px 6px 6px -3px #18181d;
  }

  .dark-theme .mobile-main-header form[role="search"].active input {
    background: #2a2e3f;
    box-shadow: 0 1px 15px 1px rgb(58, 58, 75);
  }

  .dark-theme .mobile-main-header form[role="search"] button[type="reset"] {
    background: var(--primary02);
  }

  .dark-theme .mobile-main-header .input-group-btn .btn i {
    color: #848597;
  }
}

@media (max-width: 991px) {
  .dark-theme .btn.btn-default.nav-link.resp-btn {
    background: transparent;
  }

  .dark-theme .responsive-logo .header-logo .mobile-logo.logo-1 {
    display: none;
  }
}

.dark-theme .light-layout {
  display: block;
}

.dark-theme .dark-layout {
  display: none;
}

.dark-theme .card-title {
  color: #bfc1d4;
}

@media (max-width: 991.98px) {
  .dark-theme .header-brand .desktop-dark {
    display: block !important;
    margin: 0 auto !important;
  }
}

.dark-theme .responsive-logo .mobile-logo.dark-logo-1 {
  display: block;
  margin: 0 auto;
}

.dark-theme #checkoutsteps > .steps .number {
  background-color: var(--primary02);
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.dark-theme #checkoutsteps .checkoutline {
  background-color: #3d4152;
}

.dark-theme #checkoutsteps > .steps a {
  color: #9a98b7;
}

.dark-theme .custom-select {
  border: 1px solid #3d4152;
}

.dark-theme .custom-select {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #484863;
}

.dark-theme #checkoutsteps .item .thumb {
  border: 0px solid #24243e;
}

.dark-theme #checkoutsteps .item {
  border-bottom: 1px solid #484863;
}

.dark-theme .card-pay .tabs-menu.nav a {
  color: var(--primary-bg-color);
  background: var(--primary02);
}

.dark-theme .card-pay .tabs-menu li a.active {
  background: var(--primary-bg-color);
  color: #ffffff;
}

.dark-theme .card-pay .tabs-menu.nav li {
  border-right: 1px solid #24243e;
}

.dark-theme .wizard > .actions .disabled a {
  background-color: var(--primary02);
}

.dark-theme .fc-theme-standard .fc-list-day-cushion {
  background-color: #2a2e3f;
}

.dark-theme .fc-theme-standard .fc-list-day-cushion .fc-list-day-text,
.dark-theme .fc-theme-standard .fc-list-day-cushion .fc-list-day-side-text {
  color: #dedefd !important;
}

.dark-theme .sidebar {
  border-left: 1px solid #3d4152;
}

.dark-theme .tree li {
  background: #141432;
}

.dark-theme .bg-light {
  background-color: #2a2e3f !important;
}

.dark-theme .page-item.active .page-link-outline {
  background-color: #2a2e3f;
  border: 1px solid var(--primary-bg-color);
}

.dark-theme .page-link-outline {
  background-color: #2a2e3f;
}

.dark-theme .panel-body {
  border: 1px solid #3d4152;
}

.dark-theme .accordion .card-header a {
  border: 1px solid #3d4152;
}

.dark-theme .accordion .card-header a.collapsed {
  border-bottom-color: #3d4152;
}

.dark-theme .bg-white {
  background-color: #2a2e3f !important;
}

.dark-theme .fc-theme-standard .fc-list {
  border: 1px solid #3d4152;
}

.dark-theme .fc-event,
.dark-theme .fc-event-dot {
  color: #fff !important;
}

.dark-theme .fc-theme-standard td,
.dark-theme .fc-theme-standard th {
  border: 1px solid #3d4152;
}

.dark-theme .alert-dark {
  color: #ffffff;
  background-color: var(--primary02);
  border-color: #3d4152;
}

.dark-theme .alert-light {
  color: #a9abbd;
  background-color: #2a2e3f;
  border-color: #3d4152;
}

.dark-theme .alert-primary .alert-link {
  color: var(--primary-bg-color);
}

.dark-theme .alert-secondary .alert-link {
  color: #f74f75;
}

.dark-theme .alert-success .alert-link {
  color: #24d5b8;
}

.dark-theme .alert-danger .alert-link {
  color: #f34343;
}

.dark-theme .alert-warning .alert-link {
  color: #ffbd5a;
}

.dark-theme .alert-info .alert-link {
  color: #4ec2f0;
}

.dark-theme .alert-light .alert-link {
  color: #bdbdef;
}

.dark-theme .alert-dark .alert-link {
  color: #fff;
}

.dark-theme .alert-default {
  color: #ffffff;
  background-color: var(--primary02);
  border-color: #3d4152;
}

.dark-theme .badge.bg-white {
  background-color: #fff !important;
}

.dark-theme .badge.bg-light {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.dark-theme .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: transparent;
}

.dark-theme .custom-switch-indicator-xl {
  background: var(--primary02);
  border: 1px solid #484863;
}

.dark-theme .custom-switch-indicator {
  background: var(--primary02);
  border: 1px solid #484863;
}

.dark-theme .custom-switch-indicator-lg,
.dark-theme .dark-theme .custom-switch-indicator-xl {
  background: var(--primary02);
  border: 1px solid #3f4167;
}

.dark-theme .custom-switch-indicator:before,
.dark-theme .custom-switch-indicator-md:before,
.dark-theme .custom-switch-indicator-lg:before {
  background: #1c1c29;
}

.dark-theme .wizard > .steps a .number,
.dark-theme .wizard > .steps a:hover .number,
.dark-theme .wizard > .steps a:active .number {
  background-color: var(--primary02);
}

.dark-theme .breadcrumb-item + .breadcrumb-item::before {
  color: #5f626c;
}

.dark-theme .tree li.branch {
  background: #141432;
}

.dark-theme .tree li.branch li {
  background: #141432;
}

.dark-theme .tree li {
  border: 1px solid #2a2e3f;
}

.dark-theme .nav.panel-tabs .side-menu__icon {
  color: #a9abbd;
  fill: #a9abbd;
}

.dark-theme .panel-heading1 {
  background: var(--primary02);
  color: var(--primary-bg-color);
}

.dark-theme #accordion11 .panel-body {
  border: 1px solid transparent;
}

.dark-theme .accor h4 a {
  border-bottom: 1px solid #36385c;
  background: var(--primary02) !important;
  color: var(--primary-bg-color) !important;
}

.dark-theme .btn-dark-light {
  border-color: #2b2b55;
}

.dark-theme .main-nav-line .nav-link {
  border: 1px solid #3d4152;
}

.dark-theme .dropdown-menu {
  box-shadow: 0px 16px 18px rgba(28, 28, 39, 0.2) !important;
}

.dark-theme .page-link-outline {
  border: 1px solid #424364;
}

.dark-theme .tag {
  border: 1px solid #3d4152;
}

.dark-theme .tag-addon {
  background: #2a2e3f;
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a {
  border: 1px solid #3d4152;
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a {
  border: 1px solid #3d4152;
}

.dark-theme .ql-snow.ql-toolbar button {
  border: 0px solid #3d4152;
}

.dark-theme code {
  color: #c9c9c9;
}

.dark-theme .profile-cover__info .nav strong,
.dark-theme .profile-tab .main-nav-line .nav-link.active {
  border: 1px solid #3d3d60;
}

.dark-theme .profile-cover__info .nav li:not(:first-child) {
  border-left: 1px solid rgba(47, 47, 78, 0.2);
}

.dark-theme .Choose1 {
  border: #3d4152 solid 1px;
}

.dark-theme .card-category1 {
  color: #fcfcff;
}

.dark-theme .bd-s {
  border-color: #484863 !important;
}

.dark-theme .bg-dark {
  background-color: var(--primary02) !important;
}

.dark-theme .fc .fc-daygrid-day-number {
  color: rgba(253, 250, 250, 0.6117647059);
}

.dark-theme .fc-list-table td {
  border: 0;
}

.dark-theme .card-pay .tabs-menu.nav {
  background: #2a2e3f;
}

.dark-theme .list-group-item.active {
  color: #fff;
  background-color: var(--primary02) !important;
  border-color: var(--primary02) !important;
}

.dark-theme .bs-popover-start > .popover-arrow::before,
.dark-theme
  .bs-popover-auto[data-popper-placement^="left"]
  > .popover-arrow::before {
  border-left-color: #3d4152;
}

.dark-theme .bs-popover-top > .popover-arrow::before,
.dark-theme
  .bs-popover-auto[data-popper-placement^="top"]
  > .popover-arrow::before {
  border-top-color: #3d4152;
}

.dark-theme .bs-popover-bottom > .popover-arrow::before,
.dark-theme
  .bs-popover-auto[data-popper-placement^="bottom"]
  > .popover-arrow::before {
  border-bottom-color: #3d4152;
}

.dark-theme .bs-popover-end > .popover-arrow::before,
.dark-theme
  .bs-popover-auto[data-popper-placement^="right"]
  > .popover-arrow::before {
  border-right-color: #3d4152;
}

.dark-theme .popover {
  box-shadow: 4px 8px 16px 0 #141b2d;
}

.dark-theme a.tag-addon:hover {
  background: #2a2e3f;
}

.dark-theme .bootstrap-tagsinput input {
  color: #fff !important;
}

.dark-theme .accordion .card-body {
  border: 1px solid #3d4152;
}

.dark-theme ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

@media (min-width: 769px) {
  .dark-theme .main-nav-line .nav-link + .nav-link {
    border: 1px solid #3d4152;
  }
}

@media (max-width: 991px) {
  .dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right: 14px solid #353949 !important;
    border-left: 0 solid #2a2e3f !important;
  }
}

@media (max-width: 991.98px) {
  .dark-theme .responsive-navbar.navbar .navbar-collapse {
    border-bottom: 1px solid #3c3f50;
    border-top: 1px solid #3c3f50;
  }
}

.dark-theme .btn-light svg {
  fill: rgba(255, 255, 255, 0.5);
}

.dark-theme
  .datepicker
  > .datepicker_inner_container
  > .datepicker_timelist::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.5) !important;
}

.dark-theme
  .datepicker
  > .datepicker_inner_container
  > .datepicker_timelist::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5215686275) !important;
}

.dark-theme .main-toggle {
  background-color: rgba(255, 255, 255, 0.2705882353);
}

.dark-theme .main-toggle span {
  background-color: rgba(0, 0, 0, 0.8117647059);
}

.dark-theme
  .ff_fileupload_wrap
  table.ff_fileupload_uploads
  td.ff_fileupload_summary
  .ff_fileupload_filename
  input {
  background-color: var(--primary02);
  border: 0px solid #ffffff;
  color: #ffffff;
}

.dark-theme .main-dropdown-form-demo .dropdown-title {
  color: #ffffff;
}

.dark-theme .form-check-input {
  background-color: #9295a1;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.dark-theme .profile-tab.tab-menu-heading .main-nav-line .nav-link {
  border: 1px solid #3d3d60;
}

@media (min-width: 1135px) {
  .dark-theme .main-header-left .form-control {
    width: 280px;
  }
}

.dark-theme .fc .fc-list-sticky .fc-list-day > * {
  background: rgba(0, 0, 0, 0.4509803922);
}

@media (min-width: 993px) {
  .dark-theme .main-header-left .btn {
    background-color: transparent;
    border: 0px solid rgba(255, 255, 255, 0.1);
  }
}

.dark-theme .main-header .dropdown-menu {
  border: 1px solid #484863 !important;
}

.dark-theme #country-selector .modal-header {
  border-color: #484863 !important;
}

.dark-theme .btn-country {
  color: #eaecf3;
}

.dark-theme #country-selector .modal-body {
  /* background-image: url(../../assets/img/png/countrymap1.png) !important; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.dark-theme .btn-check:checked + .btn-country {
  color: #000;
}

.dark-theme .sidebar .tabs-menu ul li .active .side-menu__icon {
  color: #fff;
  fill: #fff;
}

.dark-theme .chat .msg_cotainer {
  background-color: var(--primary02);
}

.dark-theme .primary-custom-card:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #2a2e3f !important;
  opacity: 0.5;
  left: 0;
  right: 0;
  border-radius: 5px;
  top: 0;
  bottom: 0;
}

.dark-theme .apexcharts-legend-text {
  color: #eaecf3 !important;
}

.dark-theme .apexcharts-menu-icon svg {
  fill: #a9abbd;
}

.dark-theme .apexcharts-menu-icon:hover svg {
  fill: #a9abbd !important;
}

.dark-theme .apexcharts-menu {
  background: #2a2e3f;
  border: 1px solid #484863;
}

.dark-theme .apexcharts-gridlines-horizontal line {
  stroke: #323254;
}

.dark-theme .apexcharts-menu-item:hover {
  background: #2a2e3f !important;
}

.dark-theme .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4) !important;
}

.dark-theme .select2-dropdown {
  border-color: #484863 !important;
}

@media (max-width: 991px) {
  .dark-theme .responsive-logo .mobile-logo {
    /*     margin-top: 3px !important; */
  }
}

.dark-theme form[role="search"].active input {
  box-shadow: 5px 6px 6px -3px rgba(0, 0, 0, 0.4);
}

.dark-theme .navbar-toggler-icon {
  color: #a9abbd;
}

.dark-theme .card-options a:not(.btn) {
  color: #a9abbd;
}

.dark-theme .side-menu .slide .side-menu__item.active .side-menu__label {
  color: #eaecf3 !important;
}

.dark-theme .irs-modern .irs-slider {
  background-color: #2a2e3f;
}

.dark-theme .irs-modern .irs-slider::before {
  background-color: #2a2e3f;
}

.dark-theme .irs-outline .irs-slider {
  background-color: #2a2e3f;
}

.dark-theme .apexcharts-xaxistooltip {
  color: #ffffff;
  background: #2a2e3f;
  border: 1px solid #484863;
}

.dark-theme .apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #2a2e3f !important;
  border-color: #484863;
}

.dark-theme .apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #2a2e3f !important;
  border-color: #484863;
}

.dark-theme .twentytwenty-handle {
  border: 2px solid #3d4152;
  background: rgba(0, 0, 0, 0.6);
}

.dark-theme .twentytwenty-left-arrow {
  border-right: 10px solid #000;
}

.dark-theme .twentytwenty-right-arrow {
  border-left: 10px solid #000;
}

.dark-theme .twentytwenty-horizontal .twentytwenty-handle:before,
.dark-theme .twentytwenty-horizontal .twentytwenty-handle:after,
.dark-theme .twentytwenty-vertical .twentytwenty-handle:before,
.dark-theme .twentytwenty-vertical .twentytwenty-handle:after {
  background: #2a2e3f;
}

.dark-theme .twentytwenty-horizontal .twentytwenty-handle:before {
  box-shadow: 0 3px 0 #2a2e3f, 0px 0px 12px #2a2e3f;
}

.dark-theme .twentytwenty-horizontal .twentytwenty-handle:after {
  box-shadow: 0 -3px 0 #2a2e3f, 0px 0px 12px #2a2e3f;
}

.dark-theme .twentytwenty-up-arrow {
  border-bottom: 6px solid #000;
}

.dark-theme .twentytwenty-down-arrow {
  border-top: 6px solid #000;
}

.dark-theme a.option-dots {
  border: 1px solid #454560;
}

.dark-theme .owl-nav button {
  background: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid #3d4152 !important;
}

.dark-theme .avatar-list-stacked .avatar1 {
  box-shadow: #3d4152 0px 0px 0px 2px !important;
}

.dark-theme .btn-light:focus,
.dark-theme .btn-light.focus {
  background-color: #2a2e3f !important;
  box-shadow: none;
  border-color: #3d4152 !important;
}

.dark-theme .btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #2a2e3f;
  color: #a9abbd;
  border-color: #3d4152;
}

.dark-theme .dropdown-item.active,
.dark-theme .dropdown-item:active {
  background-color: #2b2f40;
}

.dark-theme .dropdown-menu .dropdown-header {
  border-bottom: 1px solid #3d4152;
}

.dark-theme .tab-menu-heading {
  border-bottom: 0px solid #e9edf4 !important;
}

.dark-theme .accor div a {
  border-bottom: 0px solid #fbfbfd;
  color: #a9abbd;
}

.dark-theme #user-datatable thead th {
  background-color: #2a2e3f !important;
}

.dark-theme .background-image-blog {
  /* background-image: url(../../assets/img/photos/error.jpg) !important; */
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 320px;
  position: relative;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-radius: 0px;
}

.dark-theme .mail-option .dropdown-menu > li > a {
  color: #c3c7d1;
}

.dark-theme .dropdown-menu > li > a:focus {
  background: #272738;
}

.dark-theme .main-chat-footer {
  border-top: 1px solid #484863;
}

.dark-theme .shared-files {
  border: 1px solid #3b3f4f;
}

.dark-theme .nice-select .list {
  border: 1px solid #484863;
}

.dark-theme .tabs-menu1 ul li a {
  color: #eaecf3;
}

.dark-theme .btn-white {
  color: #ffffff;
  background-color: #1d1d2a;
  border-color: #484863;
}

.dark-theme .theme-container button {
  color: #fff !important;
  background-color: #1d1d2a;
  border: 1px solid #484863;
}

.dark-theme .theme-container1 button {
  color: #fff !important;
  background-color: #1d1d2a;
  border: 1px solid #484863;
}

.dark-theme .theme-container2 button {
  color: #fff !important;
  background-color: #1d1d2a;
  border: 1px solid #484863;
}

.dark-theme .intl-tel-input input {
  border: 1px solid rgba(225, 230, 241, 0.1);
  background: #1c1c29;
  color: #fff;
}

.dark-theme .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
  border-right: 1px solid #484863;
  border-radius: 0px 0px 0px 3px;
}

.dark-theme .SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
  background-color: #2a2e3f;
}

.dark-theme .notification .notification-body {
  background: #2a2e3f;
}

.dark-theme .notification .notification-body:before {
  border-right-color: #2a2e3f;
}

.dark-theme .notification .notification-icon a {
  background: #1d1d2a;
}

.dark-theme .notification-badge {
  background-color: rgba(255, 255, 255, 0.4);
  color: #eaecf3;
}

.dark-theme .pricing-tabs ul.nav-price li a {
  background: #2a2e3f;
}

.dark-theme .pricing-tabs ul.nav-price {
  border: 1px solid #484863;
}

.dark-theme .pricing-tabs ul.nav-price li a.active {
  color: #fff;
  background-color: var(--primary-bg-color);
}

.dark-theme a.text-primary:hover,
.dark-theme a.text-primary:focus {
  color: var(--primary-bg-color) !important;
}

.dark-theme .panel-heading2 {
  background: #2a2e3f !important;
  color: #eaecf3 !important;
}

.dark-theme .gray-set .bg-gray-100 {
  color: #000;
}

.dark-theme .gray-set .bg-gray-200 {
  color: #000;
}

.dark-theme .gray-set .bg-gray-300 {
  color: #000;
}

.dark-theme .gray-set .bg-gray-400 {
  color: #000;
}

.dark-theme .richText .richText-editor {
  background-color: #2a2e3f;
  border-left: transparent solid 2px;
}

.dark-theme .richText {
  border: #484863 solid 1px !important;
  background-color: #2a2e3f !important;
}

.dark-theme .richText .richText-toolbar ul {
  border-bottom: #484863 solid 1px;
}

.dark-theme .richText .richText-toolbar ul li a {
  color: #eaecf3;
}

.dark-theme .ck.ck-editor__main > .ck-editor__editable {
  background: #2a2e3f;
}

.dark-theme .ck.ck-toolbar {
  border: 1px solid #484863 !important;
  background: #2a2e3f !important;
  color: #eaecf3 !important;
}

.dark-theme .ck.ck-editor__main > .ck-editor__editable {
  border: 1px solid #484863 !important;
}

.dark-theme .ck.ck-reset_all * {
  color: #eaecf3 !important;
}

.dark-theme .ck.ck-button,
.dark-theme a.ck.ck-button {
  background: transparent !important;
  border: 1px solid #484863;
}

.dark-theme .richText .richText-undo,
.dark-theme .richText .richText-redo {
  border-right: #efefef solid 0px;
}

.dark-theme .richText .richText-toolbar ul li a:hover {
  background-color: #2a2e3f;
}

.dark-theme
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  .richText-dropdown {
  background-color: #272738;
  border: #484863 solid 1px;
}

.dark-theme
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  ul.richText-dropdown
  li
  a {
  display: block;
  border-bottom: #484863 solid 1px;
}

.dark-theme
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  .richText-dropdown
  .richText-dropdown-close {
  background: rgba(255, 255, 255, 0);
  color: #ffffff;
}

.dark-theme
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  ul.richText-dropdown
  li
  a:hover {
  background-color: #272738;
}

.dark-theme
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  ul.richText-dropdown
  li
  a:hover {
  background-color: #272738;
}

.dark-theme .richText .richText-form input[type="text"],
.dark-theme .richText .richText-form input[type="file"],
.dark-theme .richText .richText-form input[type="number"],
.dark-theme .richText .richText-form select {
  border: #484863 solid 1px;
  background-color: #1d202c;
  color: #fff;
}

.dark-theme .ql-snow .ql-tooltip {
  background-color: #2a2e3f;
  box-shadow: 0px 0px 5px #000;
  border: 1px solid #484863;
  color: #eaecf3;
}

.dark-theme .ql-snow .ql-tooltip input[type="text"] {
  border: 1px solid #484863;
  background-color: #2a2e3f;
  color: #eaecf3;
}

.dark-theme .ck.ck-list {
  background: #272738;
}

.dark-theme .ck.ck-dropdown__panel,
.dark-theme .ck.ck-balloon-panel {
  border: 1px solid #484863 !important;
  box-shadow: 0 3px 9px 0 #000 !important;
}

.dark-theme .ck-rounded-corners .ck.ck-dropdown__panel,
.dark-theme .ck.ck-dropdown__panel.ck-rounded-corners,
.dark-theme .ck.ck-list {
  border-radius: 0px !important;
}

.dark-theme .ck.ck-reset,
.dark-theme .ck.ck-reset_all,
.dark-theme .ck.ck-reset_all * {
  background: #2a2e3f;
}

.dark-theme .ck.ck-input-text {
  border: 1px solid #484863 !important;
}

.dark-theme
  .ck.ck-labeled-field-view
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck.ck-label {
  background-color: #2a2e3f;
}

.dark-theme .ck.ck-balloon-panel[class*="arrow_n"]:after {
  border-bottom-color: #2a2e3f;
  margin-top: 0;
}

.dark-theme .ck .ck-fake-link-selection_collapsed {
  outline: 0px;
}

.dark-theme .main-error-wrapper h1 {
  color: #eaecf3;
}

.dark-theme .main-error-wrapper h2 {
  color: #eaecf3;
}

.dark-theme .main-signup-header label {
  color: #eaecf3;
}

.dark-theme .main-signup-header .form-control:focus {
  border-color: #484863;
}

.dark-theme .main-signin-header .form-control:focus {
  border-color: #484863;
}

.dark-theme .note-editor.note-frame.panel.panel-default .panel-heading {
  background-color: #2a2e3f;
  border-bottom: 1px solid #484863;
}

.dark-theme .note-editor.note-airframe,
.dark-theme .note-editor.note-frame {
  border: 1px solid #484863;
}

.dark-theme .note-btn.btn-default {
  background-color: #2a2e3f;
  border-color: #484863;
}

.dark-theme .note-btn i,
.dark-theme .note-btn span {
  color: #eaecf3;
}

.dark-theme .ql-wrapper-demo {
  background-color: #2a2e3f;
}

.dark-theme #global-loader {
  background-color: #000;
}

.dark-theme #videomodal .modal-content,
.dark-theme #audiomodal .modal-content {
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.5);
}

.dark-theme .file-image-1 {
  border: 1px solid #484863;
}

.dark-theme .table-striped > tbody > tr:nth-of-type(2n + 1) > * {
  color: rgba(255, 255, 255, 0.7);
  background-color: #363b50;
}

.dark-theme
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme .table-hover > tbody > tr:hover > * {
  color: #ffffff !important;
}

.dark-theme table.table-bordered.dataTable th:last-child,
.dark-theme table.table-bordered.dataTable th:last-child {
  border-right-width: 0px !important;
}

.rtl.dark-theme table.table-bordered.dataTable th:last-child,
.rtl.dark-theme table.table-bordered.dataTable th:last-child,
.rtl.dark-theme table.table-bordered.dataTable td:last-child,
.rtl.dark-theme table.table-bordered.dataTable td:last-child {
  border-right-width: 1px !important;
  border-left-width: 1px !important;
}

.dark-theme.rtl .sidebar {
  border-right: 1px solid #3d4152;
}

.dark-theme .note-editor .btn-default:not(:disabled):not(.disabled):active {
  background-color: #2a2e3f;
}

.dark-theme .main-header-right .dropdown-menu:before {
  background: #272738;
  border-top: 1px solid #484863;
  border-left: 1px solid #484863;
}

.dark-theme .SumoSelect.disabled > .CaptionCont {
  background-color: #383d52;
}

.dark-theme .fc-theme-standard .fc-popover {
  background-color: #2a2e3f;
  border-color: #484863;
}

.dark-theme .fc-theme-standard .fc-popover-header {
  background-color: #2a2e3f;
}

.dark-theme .note-btn.btn-default:not(:disabled):not(.disabled).active {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.rtl.dark-theme
  .vtimeline
  .timeline-wrapper.timeline-inverted
  .timeline-panel:after {
  border-right: 0px solid #2a2e3f;
  border-left: 14px solid #353949;
}

.rtl.dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
  border-left: 0 solid #2a2e3f;
  border-right: 14px solid #353949;
}

.rtl.dark-theme .notification .notification-body:before {
  border-left-color: #2a2e3f;
  border-right-color: transparent !important;
}

.dark-theme table.table-bordered.dataTable th:last-child,
.dark-theme table.table-bordered.dataTable th:last-child,
.dark-theme table.table-bordered.dataTable td:last-child,
.dark-theme table.table-bordered.dataTable td:last-child {
  border-right-width: 0px !important;
}

.dark-theme .primary-custom-card1:before {
  background-color: #2a2e3f;
}

.dark-theme .btn-country.active {
  background-color: #2a2e3f;
}

.rtl.dark-theme .border-start {
  border-right: 1px solid #3c3c60 !important;
  border-left: 0 !important;
}

.dark-theme.app.sidebar-mini .desktop-logo.logo-dark {
  display: block;
}

.dark-theme .main-cart-list .main-cart-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark-theme .datetimepicker-hours span.hour:hover,
.dark-theme .datetimepicker-hours span.minute:hover,
.dark-theme .datetimepicker-hours span.month:hover,
.dark-theme .datetimepicker-hours span.year:hover {
  background: #2a2e3f;
}

.dark-theme .datetimepicker-minutes span.hour:hover,
.dark-theme .datetimepicker-minutes span.minute:hover,
.dark-theme .datetimepicker-minutes span.month:hover,
.dark-theme .datetimepicker-minutes span.year:hover {
  background: #2a2e3f;
}

.dark-theme.color-header .pulse:before {
  background: #086858 !important;
}

.dark-theme .Basicwizard {
  border-top: 1px solid #484863 !important;
  border-bottom: 1px solid #484863 !important;
}

.dark-theme .bordera {
  border-right: 1px solid #484863 !important;
}

.dark-theme .background__single-value {
  color: #fff !important;
}

.dark-theme .pcr-app {
  background: #2a2e3f !important;
}

.dark-theme .ql-wrapper {
  border: 1px solid #484863;
}

.dark-theme .msl {
  border: 1px solid #484863;
}

.dark-theme .msl .msl-input:before {
  color: #fff !important;
}

.dark-theme .form-control:disabled {
  background-color: #383d52;
  opacity: 0.5;
}

.dark-theme .form-control[readonly] {
  background-color: #383d52;
}

.dark-theme .form-control-plaintext {
  color: rgba(255, 255, 255, 0.8);
}

.dark-theme .form-control-plaintext::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-theme.rtl
  .main-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 0;
}

.dark-theme.error-page1 .tabs-menu1 ul li button.active {
  border-bottom: 3px solid var(--primary-bg-color) !important;
  background: #2a2e3f !important;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
}

.dark-theme.error-page1 .nav.panel-tabs button.active {
  color: var(--primary-bg-color) !important;
}

.dark-theme.error-page1 .tabs-menu1 ul li button {
  border-bottom: 3px solid #ededf5 !important;
  background: #2a2e3f !important;
  border-top: transparent !important;
  border-left: transparent !important;
  border-right: transparent !important;
}

.dark-theme .export-table .rdt_TableHead {
  border: 1px solid #484863 !important;
}

.dark-theme #accordion11 .accordion-button.collapsed::after {
  background-image: none !important;
  content: "\e92d";
  position: absolute;
  font-family: "feather";
  right: 10px;
  top: 0px;
  font-size: 16px;
  line-height: 2.3;
  transition: all 0.5s;
  transform: scale(1);
  color: var(--primary-bg-color) !important;
}

.dark-theme #accordion11 .accordion-button::after {
  background-image: none !important;
  content: "\e930";
  position: absolute;
  font-family: "feather";
  right: 10px;
  top: 6px;
  font-size: 16px;
  line-height: 2.3;
  transition: all 0.5s;
  transform: scale(1);
  color: var(--primary-bg-color);
}

.dark-theme .checkoutstepss .item {
  border-bottom: 1px solid #484863;
}

.dark-theme .pri-tabs-heading .nav-pills {
  border: 1px solid #484863;
}

.dark-theme .pri-tabs-heading .nav-pills .nav-item .nav-link {
  background-color: #2a2e3f;
}

.dark-theme .pri-tabs-heading .nav-pills .nav-item .nav-link.active {
  background-color: var(--primary-bg-color);
}

.dark-theme .chk-all button,
.dark-theme .hidden-phone button {
  color: #fff !important;
}

.dark-theme .card.customs-cards {
  border: 1px solid #484863 !important;
}

.dark-theme .swiper-slide-thumb-active {
  border: 1px solid #484863 !important;
  background: var(--primary02) !important;
}

.dark-theme .apexcharts-tooltip {
  background: #4a4f62 !important;
  color: #fff !important;
  border: 1px solid #484863 !important;
}

.dark-theme .apexcharts-tooltip-title {
  background: #383d52 !important;
  color: #fff !important;
  border-bottom: #484863 !important;
}

.dark-theme .apexcharts-tooltip-marker {
  background: #24d5b8 !important;
}

.dark-theme .sidebar-right .nav.panel-tabs .active .side-menu__icon {
  fill: #fff !important;
}

.dark-theme .sidebar-right .tabs-menu .nav-item a {
  padding: 8px 8px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #484863;
  font-weight: 500;
  background: var(--primary02);
  color: #4a4a69;
  margin: 0 2px;
}

.dark-theme .sidebar-right .tabs-menu .nav-item a.active {
  background-color: var(--primary-bg-color);
}

.dark-theme .main-header-center .form-control:focus {
  border: 1px solid #484863 !important;
}

.dark-theme .accordiondclose .accordion-header button {
  border: 1px solid #484863 !important;
}

.dark-theme .productdesc .tabs-menu1 .nav-tabs .nav-item .nav-link.active {
  background: var(--primary-bg-color);
  color: #fff !important;
}

.dark-theme.scrollable-layout.light-menu.light-header .app-sidebar {
  border-right: 1px solid #ededf5;
}

.dark-theme .ql-wrapper .rdw-dropdown-optionwrapper {
  background: #000 !important;
}

.dark-theme .ql-wrapper .rdw-dropdownoption-active {
  background: #000 !important;
  color: #24d5b8 !important;
}

.dark-theme .ql-wrapper .rdw-dropdownoption-highlighted {
  background: transparent !important;
}

.dark-theme .ql-wrapper .rdw-dropdownoption-default {
  font-size: 13px;
}

.dark-theme .ql-wrapper .rdw-colorpicker-modal,
.dark-theme .ql-wrapper .rdw-link-modal,
.dark-theme .ql-wrapper .rdw-link-modal-input {
  background: #2a2e3f !important;
  border: 1px solid #484863;
  box-shadow: none;
}

.dark-theme .ql-wrapper .rdw-link-modal-btn,
.dark-theme .ql-wrapper .rdw-embedded-modal-link-input,
.dark-theme .ql-wrapper .rdw-image-modal-size-input,
.dark-theme .ql-wrapper .rdw-image-modal-btn,
.dark-theme .ql-wrapper .rdw-embedded-modal-btn,
.dark-theme .ql-wrapper .rdw-image-modal,
.dark-theme .ql-wrapper .rdw-image-modal-url-input,
.dark-theme .ql-wrapper .rdw-emoji-modal,
.dark-theme .ql-wrapper .rdw-embedded-modal-size-input,
.dark-theme .ql-wrapper .rdw-embedded-modal {
  background: #2a2e3f !important;
  border: 1px solid #484863;
  box-shadow: none;
  color: #fff;
}

.dark-theme .ql-wrapper .rdw-embedded-modal-header-label {
  display: none !important;
}

.dark-theme #accordion11 .panel-body {
  border-top: 1px solid #484863 !important;
}

.dark-theme .swal2-popup,
.dark-theme .swal2-validation-message {
  background-color: #2a2e3f !important;
  color: #fff !important;
}

.dark-theme .MuiDialog-paperScrollPaper {
  background-color: #2a2e3f !important;
  color: #fff !important;
}

.dark-theme .MuiDialog-paperScrollPaper .MuiDialogContentText-root {
  color: #fff !important;
}

.dark-theme .accordion-item {
  background-color: transparent !important;
}

.dark-theme .accordion .accordion-body {
  background-color: #2a2e3f !important;
}

.dark-theme #headingOne1 .panel-heading1 .accordion-header button,
.dark-theme .panel-group1 .panel-heading1 .accordion-header button {
  background: var(--primary02) !important;
  color: var(--primary-bg-color) !important;
}

.dark-theme .accordion.accor .accordion-header button {
  color: #fff !important;
  background-color: var(--primary02) !important;
  border: 1px solid #484863 !important;
}

.dark-theme .accordion .accordion-header button {
  color: #fff !important;
  background-color: #2a2e3f !important;
}

.dark-theme .tab_wrapper.right_side .nav-pills .nav-item {
  border: 1px solid #484863 !important;
}

.dark-theme .tab_wrapper.right_side .nav-pills a.active:before {
  background-color: transparent !important;
}

.dark-theme .tab_wrapper.right_side .nav-pills a:after {
  background: var(--primary-bg-color) !important;
}

.dark-theme .tabs-style-4 .nav.nav-pills .nav-item a.active {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .tab_wrapper.right_side .tab-content {
  border: 1px solid #484863;
}

.dark-theme .tabs-style-4 .nav.nav-pills .nav-item a {
  background-color: #2a2e3f !important;
  border: 1px solid #484863 !important;
}

.dark-theme .tabs-style-3 .nav.panel-tabs li button {
  background: #2a2e3f !important;
  color: #fff;
  border: 1px solid #484863 !important;
}

.dark-theme .bg-dark .bg-gray-800 {
  background-color: #2a2e3f !important;
}

.dark-theme .bg-gray-100.nav-bg .nav-tabs .nav-link.active {
  background-color: var(--primary-bg-color) !important;
}

.dark-theme .bg-dark {
  background-color: #383d51 !important;
}

.dark-theme .bg-gray-100.nav-bg .nav-tabs .nav-link {
  background-color: #383d52;
}

.dark-theme
  .deni-react-treeview-container.classic
  .deni-react-treeview-item-container.classic
  .icon-and-text:hover:not(.select-row) {
  background-color: transparent !important;
}

.dark-theme .deni-react-treeview-item-container .icon-and-text:hover {
  border-style: none !important;
}

.dark-theme .tree li {
  background: #383d52 !important;
}

.dark-theme
  .deni-react-treeview-container.classic
  .deni-react-treeview-item-container.classic {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dark-theme
  .deni-react-treeview-container.classic
  .deni-react-treeview-item-container.classic
  .expand-button {
  color: var(--primary-bg-color) !important;
}

.dark-theme .main-content-contacts .main-nav-line .nav-link {
  border: none !important;
}

.dark-theme .tabs-style-4 .nav.panel-tabs .nav-item a.active {
  background: var(--primary-bg-color);
  color: #fff;
}

.dark-theme .MuiSlider-markLabel,
.dark-theme .MuiSlider-valueLabelLabel {
  color: #eaecf3 !important;
}

.dark-theme .table-responsive .selectpage {
  background: #383d52;
  color: #fff;
}

.dark-theme .table-responsive select {
  color: #fff;
  border-radius: 5px;
}

.dark-theme .table-responsive span input {
  border: 1px solid #555c6e !important;
}

.dark-theme .borderrigth {
  border-right: 1px solid #555c6e;
}

.dark-theme .rdt_Table {
  background-color: #2a2e3f !important;
  border: 1px solid #555c6e !important;
}

.dark-theme .rdt_Table .rdt_TableHeadRow {
  background-color: #2a2e3f !important;
  color: #fff;
}

.dark-theme .rdt_Table .rdt_TableBody .rdt_TableRow {
  background-color: #2a2e3f !important;
  border: 1px solid #555c6e;
  color: rgba(255, 255, 255, 0.7);
}

.dark-theme .datatable > div:nth-child(2) {
  background-color: #2a2e3f !important;
}

.dark-theme .datatable .rdt_TableHead .rdt_TableHeadRow > div {
  border-right: 1px solid #555c6e;
}

.dark-theme .datatable .rdt_TableBody .rdt_TableRow > div {
  border-right: 1px solid #555c6e;
}

.dark-theme .rdt_Pagination {
  background-color: #2a2e3f !important;
  color: #fff;
}

.dark-theme a {
  color: #faf9fa;
}

.dark-theme .sc-llJcti {
  border: 1px solid #484863;
}

.dark-theme .rdw-option-wrapper:hover {
  box-shadow: none !important;
}

.dark-theme .MuiTypography-h5 {
  color: rgb(147, 147, 181);
}

.dark-theme .rdw-option-wrapper,
.dark-theme .rdw-fontfamily-placeholder {
  background-color: transparent;
  border: 1px solid transparent;
}

.dark-theme .rdw-fontfamily-wrapper {
  color: rgba(255, 255, 255, 0.3) !important;
}

.dark-theme .rdw-dropdown-wrapper {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  z-index: 9;
}

.dark-theme .rdw-editor-toolbar,
.dark-theme .dropzone,
.dark-theme .MuiDropzoneArea-root,
.dark-theme .background__control,
.dark-theme .selectform__control {
  border: 1px solid #484863 !important;
}

.dark-theme .background__menu,
.dark-theme .selectform__menu {
  background-color: #2a2e3f !important;
  border: 1px solid #484863 !important;
  color: #4a4a69;
}

.dark-theme .selectform__menu:hover {
  background-color: #2a2e3f !important;
  border: 1px solid #484863 !important;
  color: #4a4a69 !important;
}

.dark-theme .msl-options {
  background-color: #2a2e3f !important;
}

.dark-theme .msl-active,
.dark-theme .msl-active-up,
.dark-theme .msl-active ~ .msl-options {
  background-color: #2a2e3f !important;
}

.dark-theme .msl-option {
  border-bottom: 1px solid #484863 !important;
}

.dark-theme .Basicwizard {
  border-top: 1px solid #484863 !important;
  border-bottom: 1px solid #484863 !important;
}

.dark-theme .msl {
  border: 1px solid #484863 !important;
}

.dark-theme .msl-disabled > .msl {
  background-color: transparent !important;
}

.dark-theme .form-control {
  border: 1px solid #484863 !important;
}

.dark-theme .sc-gicCDI {
  color: #fff !important;
  fill: #fff !important;
}

.dark-theme .sc-gicCDI:disabled {
  color: #e1e1ef !important;
  fill: #e1e1ef !important;
}

.dark-theme .uselistdata .rdt_TableHeader {
  background-color: #2a2e3f !important;
}

.dark-theme .datatable .rdt_Table {
  border: 1px solid #484863 !important;
}

.dark-theme .leaflet-control-zoom-in,
.dark-theme .leaflet-control-zoom-out {
  color: #000 !important;
}

.dark-theme .text-primary {
  color: var(--primary-bg-color) !important;
}

.dark-theme.rtl #accordion11 .accordion-button::after {
  background-image: none !important;
  content: "\e930";
  position: absolute;
  font-family: "feather";
  left: 10px !important;
  right: inherit;
  top: 0px;
  font-size: 16px;
  line-height: 2.3;
  transition: all 0.5s;
  transform: scale(1);
  color: var(--primary-bg-color);
}

.dark-theme .faq #accordion11 .accordion-button.collapsed::after {
  background-image: none !important;
  content: "\e92d";
  position: absolute;
  font-family: "feather";
  right: 10px;
  top: 6px;
  font-size: 16px;
  line-height: 2.3;
  transition: all 0.5s;
  transform: scale(1);
  color: var(--primary-bg-color) !important;
}

.dark-theme .panel-heading1 .accordion-collapse.collapse.show {
  color: #fff;
}

.dark-theme .user-lock .dropdown button {
  border: 1px solid #484863;
}

.dark-theme .sc-fnykZs {
  background: #383d52;
  color: #fff;
}

.dark-theme .main-chat-footer .nav-link {
  border: 1px solid #484863;
}

.dark-theme .uselistdata .rdt_Table .rdt_TableBody .rdt_TableRow {
  background-color: #2a2e3f !important;
  border-bottom-color: #484863;
  color: rgba(255, 255, 255, 0.7);
}

.dark-theme .swal2-input:focus {
  border: 1px solid #484863 !important;
}

.dark-theme .accordiondclose .accordion-header button.collapsed {
  background-color: #2a2e3f !important;
}

.dark-theme .optiondots .btn,
.dark-theme .rti--container {
  border: 1px solid #484863 !important;
}

.dark-theme .lg-actions .lg-next,
.dark-theme .lg-actions .lg-prev {
  background-color: var(--primary-bg-color);
}

.dark-theme .tags-bg .msl-options {
  background-color: #2a2e3f !important;
}

.dark-theme .selectform__menu-list,
.dark-theme .selectform__single-value {
  color: #fff !important;
}

.dark-theme .selectform__option {
  background-color: #2a2e3f !important;
}

.dark-theme .msl-options .msl-option:hover {
  background: var(--primary-bg-color);
  color: #fff !important;
}

.dark-theme .msl-active-up .msl-options {
  background-color: #2a2e3f !important;
}

.dark-theme .intl-tel-input .country-list {
  background-color: #2a2e3f !important;
  white-space: normal;
  border: 1px solid #484863;
}

.dark-theme .intl-tel-input .country-list .divider {
  border: 1px solid transparent;
}

.dark-theme .intl-tel-input .country-list .country.highlight {
  background: transparent;
  border: transparent;
  margin: 0;
}

.dark-theme .msl-chip {
  background: transparent;
}

.dark-theme .msl-chip-delete {
  background: transparent;
  color: #fff;
}

.dark-theme .selectform__input-container {
  color: #fff !important;
}

.dark-theme .MuiInputBase-input {
  color: #eaecf3;
}

.dark-theme .react-datepicker__month-container,
.dark-theme .react-datepicker__header.react-datepicker-year-header {
  background-color: #2a2e3f;
  color: #fff;
}

.dark-theme .react-datepicker {
  border: 1px solid #484863;
}

.dark-theme .react-datepicker__header {
  border-bottom: transparent;
}

.dark-theme .react-datepicker__triangle::after {
  border: 8px solid #2a2e3f !important;
}

.dark-theme .react-datepicker__header,
.dark-theme .react-datepicker__year--container {
  background-color: #2a2e3f;
  color: #fff;
}

.dark-theme .react-datepicker__month-container {
  color: #fff;
}

.dark-theme .react-datepicker {
  background-color: transparent;
}

.dark-theme .borders {
  border-bottom: 1px solid #484863;
  border-top: 0px solid #484863;
  border-left: 1px solid #484863;
  border-right: 1px solid #484863;
}

.dark-theme .card-pay .tabs-menu.nav li button {
  color: var(--primary-bg-color);
  background: var(--primary02) !important;
}

.dark-theme .card-pay .tabs-menu li button.active {
  background: var(--primary-bg-color) !important;
  color: #ffffff;
}

.dark-theme .nvd3 .nv-legend .nv-series text {
  fill: #fff;
}

.dark-theme .nv-axisMaxMin-y {
  fill: transparent;
}

.dark-theme .main-contact-star .dropdown-menu.show {
  background-color: #2a2e3f !important;
}

.dark-theme .drop .dropzone:hover {
  background-image: linear-gradient(
    -45deg,
    rgba(250, 251, 254, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(250, 251, 254, 0.1) 50%,
    rgba(250, 251, 254, 0.1) 75%,
    transparent 75%,
    transparent
  ) !important;
  animation: stripes 2s linear infinite;
}

.dark-theme .msl-chip {
  background: transparent;
}

.dark-theme .datetimepicker-2 .MuiInputBase-input {
  color: #fff;
}

.dark-theme .rdw-block-dropdown a,
.dark-theme .rdw-fontfamily-dropdown a {
  color: #000 !important;
}

.dark-theme .rdw-editor-wrapper {
  border: 1px solid #484863;
}

.dark-theme .rdw-editor-toolbar {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 1px solid #484863 !important;
}

.dark-theme .rdw-dropdown-optionwrapper,
.dark-theme .rdw-colorpicker-modal,
.dark-theme .rdw-embedded-modal,
.dark-theme .rdw-emoji-modal,
.dark-theme .rdw-image-modal,
.dark-theme .rdw-link-modal {
  background: #2a2e3f;
  border: 1px solid #484863;
  box-shadow: none;
}

.dark-theme .rdw-dropdownoption-active,
.dark-theme .rdw-image-modal-upload-option {
  background: transparent;
}

.dark-theme .rdw-image-modal-size input,
.dark-theme .rdw-embedded-modal-link-input-wrapper input,
.dark-theme .rdw-embedded-modal-size input,
.dark-theme .rdw-link-modal input,
.dark-theme .rdw-image-modal-url-section input {
  background: transparent;
  border: 1px solid #484863;
}

.dark-theme .rdw-image-modal-btn:active {
  background: var(--primary-bg-color) !important;
  color: var(--primary-bg-color);
  border: 1px solid #484863;
}

.dark-theme .rdw-image-modal-btn {
  background: #2a2e3f;
  color: #fff;
  border: 1px solid #484863;
}

.dark-theme .rdw-embedded-modal-btn-section .rdw-image-modal-btn:active {
  background: var(--primary-bg-color) !important;
  color: var(--primary-bg-color);
  border: 1px solid #484863;
}

.dark-theme .rdw-embedded-modal-btn-section .rdw-image-modal-btn {
  background: #2a2e3f;
  color: #fff;
  border: 1px solid #484863;
}

.dark-theme .rdw-embedded-modal-btn-section .rdw-embedded-modal-btn:active {
  background: var(--primary-bg-color) !important;
  color: var(--primary-bg-color);
  border: 1px solid #484863;
}

.dark-theme .rdw-embedded-modal-btn-section .rdw-embedded-modal-btn {
  background: #2a2e3f;
  color: #fff;
  border: 1px solid #484863;
}

.dark-theme .main-contacts-list .selected .main-contact-star .dropdown-toggle {
  color: #fff !important;
}

.dark-theme
  .main-contacts-list
  .selected
  .main-contact-star
  .dropdown-toggle:hover {
  color: #fff !important;
}

.dark-theme .selectproduct__control {
  background-color: #2a2e3f !important;
  border-color: #484863 !important;
}

.dark-theme .selectproduct__menu {
  background-color: #2a2e3f !important;
  border-color: #484863 !important;
}

.dark-theme .css-1n7v3ny-option {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.dark-theme .selectproduct__placeholder {
  color: #fff !important;
}

.transparent-theme .formselect__control {
  background-color: #2a2e3f;
  border: 1px solid #484863;
  box-shadow: none;
}

.transparent-theme .formselect__placeholder {
  color: #fff !important;
}

.transparent-theme .formselect__menu {
  background-color: #2a2e3f;
}

.transparent-theme .formselect__multi-value {
  background-color: transparent;
}

.transparent-theme .formselect__multi-value__label {
  color: #fff;
}

.transparent-theme .formselect__single-value {
  color: #fff;
}

/*# sourceMappingURL=style-dark.css.map */
.dark-theme .table-header {
  background-color: var(--white10);
}

.dark-theme .table-header th {
  color: var(--black110) !important;
}

.dark-theme tbody.table-body tr {
  background-color: var(--black110) !important;
}

.dark-theme .job-column label:first-child {
  color: var(--white10) !important;
}

.dark-theme .job-column label:last-child {
  color: var(--white40) !important;
}

.dark-theme .custom-dropdown::after,
.dark-theme .custom-dropdown {
  background-color: var(--black120) !important;
}

.dark-theme .custom-dropdown label {
  color: var(--white_pure) !important;
}

.dark-theme .custom-dropdown a:hover {
  background-color: var(--black130) !important;
}

.dark-theme .page-disable {
  color: var(--setting_grey) !important;
}

.dark-theme .page-enable {
  color: var(--white10) !important;
}

.dark-theme .briefcase-icon path {
  fill: var(--black110) !important;
}

.dark-theme .user-icon path {
  stroke: var(--black110) !important;
}

.dark-theme .view-icon circle {
  stroke: var(--black110) !important;
}

.dark-theme .view-icon path {
  stroke: var(--black110) !important;
  fill: var(--white10) !important;
}

.dark-theme .btn-link:hover {
  color: var(--white100) !important;
}

.dark-theme .tableButton {
  background: transparent !important;
  color: var(--success_default) !important;
}

.dark-theme .tableButton :hover {
  background: transparent !important;
  color: var(--grey100) !important;
}

.dark-theme .activeTableButton {
  background: var(--success_default) !important;
  color: var(--black110) !important;
}

.dark-theme .activeTableButton :hover {
  background: var(--success_default) !important;
  color: var(--black110) !important;
}

.dark-theme .table-body tr:hover {
  background-color: var(--black140) !important;
}

.dark-theme .table-body tr:hover label,
.dark-theme .table-body tr:hover svg {
  color: var(--white_pure) !important;
}

.dark-theme .dashboard-usericon svg path {
  stroke: var(--warning_default) !important;
}

.dark-theme .dashboard-bagicon svg path {
  fill: var(--info_default) !important;
}

.dark-theme .inbox #apexcharts-radialbarTrack-0 {
  stroke: rgba(30, 150, 242, 0.25) !important;
}

.dark-theme .screening #apexcharts-radialbarTrack-0 {
  stroke: rgba(36, 213, 184, 0.25) !important;
}

.dark-theme .shortlist #apexcharts-radialbarTrack-0 {
  stroke: rgba(171, 141, 244, 0.25) !important;
}

.dark-theme .interview #apexcharts-radialbarTrack-0 {
  stroke: rgba(252, 125, 154, 0.25) !important;
}

.dark-theme .offer_made #apexcharts-radialbarTrack-0 {
  stroke: rgba(171, 141, 244, 0.25) !important;
}

.dark-theme .onboarding #apexcharts-radialbarTrack-0 {
  stroke: rgba(36, 213, 184, 0.25) !important;
}

.dark-theme .apexcharts-datalabel-label {
  fill: var(--black50) !important;
}

.dark-theme .apexcharts-datalabel-value {
  fill: var(--black10) !important;
}

.dark-theme .apexcharts-gridline {
  stroke: #313252 !important;
}

.dark-theme .apexcharts-grid line:last-child {
  stroke-dasharray: 1;
  stroke: #4a4f62;
}

.dark-theme .not-qualifier {
  border: 2px solid var(--black50) !important;
}

.dark-theme .Onboarding-dropdown {
  @media (min-width: 992px) {
    background: var(--black120) !important;
    outline: none;
    border: none;
  }
}

.dark-theme.dropdown-menu {
  color: var(--white_pure);
  background-color: var(--black120);
  border: 1px solid var(--black130);
}

.dark-theme .dropdown-item:hover,
.dark-theme .dropdown-item:focus {
  background-color: var(--black130);
}

.dark-theme .inbox-regular {
  background: var(--black120);
}

.dark-theme .inbox-regular label {
  color: var(--white_pure);
}

.dark-theme .inbox-onboarding label {
  color: var(--white_pure);
}

.dark-theme .inbox-onboarding {
  background: var(--black120);

  @media (min-width: 1440px) {
    border: 1px solid var(--primary_default) !important;
    background: var(--black170);
  }
}

.dark-theme .show-dropdown,
.dark-theme .show-dropdown:hover,
.dark-theme .show-dropdown:active,
.dark-theme .show-dropdown:focus {
  background-color: var(--light_default) !important;
  color: var(--black100) !important;
}

.dark-theme .email-open {
  background-color: var(--black110) !important;
}

.dark-theme .email-not-open {
  background-color: var(--black180) !important;
}

.dark-theme .candidate-info label:first-child {
  color: var(--grey100) !important;
}

.dark-theme .candidate-info label:last-child {
  color: var(--grey30) !important;
}

.dark-theme .envelop > svg path {
  fill: var(--white200) !important;
}

.dark-theme .open-envelop > svg path {
  stroke: var(--primary_default) !important;
}

.dark-theme .candidate-list {
  border: 1px solid var(--black80);
  filter: drop-shadow(1px 1px 7px rgba(154, 154, 204, 0.1));
  border-radius: 5px;
  background: var(--black80);
}

.dark-theme .candidate-name-job label {
  color: var(--grey30) !important;
}

.dark-theme .candidate-name-job > :first-child {
  color: var(--white10) !important;
}

.dark-theme .emoji-icons svg path {
  stroke: var(--grey40) !important;
  fill: var(--black110) !important;
}

.dark-theme .emoji-icon-active svg path {
  stroke: var(--success_default) !important;
}

.dark-theme .active-tab svg path {
  stroke: var(--primary_default) !important;
}

.dark-theme .active-tab svg circle {
  stroke: var(--primary_default) !important;
  fill: var(--black220) !important;
}

.dark-theme .member-invited {
  background: var(--black110) !important;
}
.dark-theme .member-active .job-column.email-user-role label {
  color: var(--white40) !important;
}
.dark-theme .member-active .job-column label {
  color: var(--white10) !important;
}
.dark-theme .member-invited .job-column label,
.dark-theme .member-invited .email-user-role label {
  color: var(--black70) !important;
}
.dark-theme .member-invited svg path {
  fill: var(--black70) !important;
}
.dark-theme .member-deactivated .job-column label,
.dark-theme .member-deactivated .email-user-role label {
  color: #4e5365 !important;
}
.dark-theme .member-deactivated svg path {
  fill: #4e5365 !important;
}
.dark-theme tbody.table-body tr.member-deactivated {
  background: #383d5280 !important;
}
.dark-theme .overview-table.member-invited:hover svg path {
  fill: var(--info_default) !important;
}
.dark-theme .overview-table.member-invited:hover label {
  color: var(--info_default) !important;
}
.dark-theme .overview-table.member-deactivated:hover label {
  color: var(--placeholder_color) !important;
}
.dark-theme .overview-table.member-deactivated:hover {
  background: #383d5299 !important;
}
.dark-theme .overview-table.member-deactivated:hover svg path {
  fill: var(--placeholder_color) !important;
}
.dark-theme .img-crop-container {
  background: var(--black120);
}
.dark-theme .ant-modal-content,
.dark-theme .ant-modal-title {
  background: var(--black110);
  color: var(--white_pure);
}
.dark-theme .ant-modal-close-x svg path {
  fill: var(--white_pure) !important;
}
.dark-theme .img-crop-control button {
  color: var(--white_pure) !important;
}
.dark-theme .ant-slider-rail {
  background: var(--white_pure) !important;
}

.dark-theme .ant-select-dropdown {
  background-color: var(--black120);
  border-color: var(--black130);
}
.dark-theme .ant-select-item-option {
  color: var(--white_pure);
}
.dark-theme .ant-select-item-option:hover {
  background: var(--black130);
}
.dark-theme .ant-select-item-option-selected {
  background-color: var(--black130) !important;
  color: var(--white_pure) !important;
}

.dark-theme .disable-message-editor .rdw-editor-wrapper {
  background: var(--black120) !important;
}

.dark-theme .disable-message-editor .rdw-option-wrapper {
  background: var(--black120) !important;
}

.dark-theme .disable-message-editor .rdw-editor-toolbar {
  background: var(--black120) !important;
}

.dark-theme .selected-card {
  border-color: var(--primary_default) !important;
}
