.lottie-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  background-color: rgba(217, 217, 217, 0.5);
}
.lottie-wrapper svg {
  background-color: #fff;
  border-radius: 5px;
}

.dark-theme .lottie-wrapper svg {
  background: var(--black110);
}
